import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetailerSalesResponsiblesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RetailerSalesResponsiblesQuery = { __typename?: 'Query', retailersRetailerSalesResponsibles?: { __typename?: 'retailersRetailerSalesResponsiblePaginator', data: Array<{ __typename?: 'retailersRetailerSalesResponsible', id: string, name: string, active: boolean }> } | null | undefined };


export const RetailerSalesResponsiblesDocument = gql`
    query RetailerSalesResponsibles {
  retailersRetailerSalesResponsibles {
    data {
      id
      name
      active
    }
  }
}
    `;

/**
 * __useRetailerSalesResponsiblesQuery__
 *
 * To run a query within a React component, call `useRetailerSalesResponsiblesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailerSalesResponsiblesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailerSalesResponsiblesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRetailerSalesResponsiblesQuery(baseOptions?: Apollo.QueryHookOptions<RetailerSalesResponsiblesQuery, RetailerSalesResponsiblesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetailerSalesResponsiblesQuery, RetailerSalesResponsiblesQueryVariables>(RetailerSalesResponsiblesDocument, options);
      }
export function useRetailerSalesResponsiblesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailerSalesResponsiblesQuery, RetailerSalesResponsiblesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetailerSalesResponsiblesQuery, RetailerSalesResponsiblesQueryVariables>(RetailerSalesResponsiblesDocument, options);
        }
export type RetailerSalesResponsiblesQueryHookResult = ReturnType<typeof useRetailerSalesResponsiblesQuery>;
export type RetailerSalesResponsiblesLazyQueryHookResult = ReturnType<typeof useRetailerSalesResponsiblesLazyQuery>;
export type RetailerSalesResponsiblesQueryResult = Apollo.QueryResult<RetailerSalesResponsiblesQuery, RetailerSalesResponsiblesQueryVariables>;