/* eslint-disable no-unused-vars */
import { createStyles, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext, useEffect, useState } from 'react'
import SortableTree, { TreeItem } from 'react-sortable-tree'
import 'react-sortable-tree/style.css'
import AddNodeForm from '../form/AddNodeForm'
import RemoveNodePopup from '../popups/RemoveNodePopup'
import DuplicateModelButton from './DuplicateModelButton'
import ExportButton from './ExportButton'
import UpdateAffectedPresetsButton from './UpdateAffectedPresetsButton'
import { formatTreeState, updateTree } from './helpers/TreeHelperFunctions'
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import TabIcon from '@material-ui/icons/Tab'
import ListIcon from '@material-ui/icons/List'
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import TextFormatOutlinedIcon from '@material-ui/icons/TextFormatOutlined'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined'
import DialpadOutlinedIcon from '@material-ui/icons/DialpadOutlined'
import ArtTrackOutlinedIcon from '@material-ui/icons/ArtTrackOutlined'
import { grey } from '@material-ui/core/colors'
import { IAddNodeForm, IRemovableItem, ITreeView } from 'ts/interfaces'
import { ModelContext } from 'context/model/ModelContext'
import { ErrorContext } from 'context/error/ErrorContext'
import { getAvailableTypes } from 'utils/Schema'
import { AddButton, RemoveButton } from '../../atoms/buttons/Buttons'
import { useMoveNodeMutation } from 'apollo/configurator/mutations/MoveNode.generated'
import { useModelSchemaQuery } from 'apollo/configurator/queries/ModelSchema.generated'
import { NodeQuery, useNodeLazyQuery } from 'apollo/configurator/queries/Node.generated'
import DownloadButton from './DownloadButton'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            height: '100%',
            maxHeight: 'calc(100vh - 260px)',
            overflow: 'scroll',
            position: 'relative'
        },
        buttonGroup: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '20px',
            position: 'sticky',
            top: 0,
            zIndex: 10,
            backgroundColor: theme.palette.background.default,
            paddingBottom: 30,
            borderBottom: '1px solid #eaeaea',
            justifyContent: 'space-between'
        },
        nodeButtons: {
            color: grey[500]
        }
    })
)

const TreeView = ({ handleNodeClick, rootNode }: ITreeView) => {
    const { data: { configuratorModelSchema: modelSchema } = {} } = useModelSchemaQuery()
    const modelSchemaObject = modelSchema && JSON.parse(modelSchema)
    const [treeState, setTreeState] = useState<TreeItem[] | null>()
    const [nodeData, setNodeData] = useState<NodeQuery['configuratorNode'] | null>()
    const [selectedNodeId, setSelectedNodeId] = useState<number | null>(null)
    const { state: modelState, setState: setModelState } = useContext(ModelContext)
    const [getNodeData] = useNodeLazyQuery({
        onCompleted: (data) => setNodeData(data?.configuratorNode)
    })
    const [form, setForm] = React.useState<IAddNodeForm>({ id: '', open: false, rowInfo: null })
    const [removableItem, setRemovableItem] = useState<IRemovableItem>({
        id: null,
        extendedNode: null,
        parent_id: null
    })
    const { setErrorState } = useContext(ErrorContext)
    const [, updateState] = React.useState({})
    const forceUpdate = React.useCallback(() => updateState({}), [])
    const classes = useStyles()

    const fetchNodeData = (id: number) => {
        if (id !== treeState?.[0]?.id) {
            getNodeData({ variables: { id: id as any } })
            setSelectedNodeId(id)
        }
    }

    const [moveNode] = useMoveNodeMutation({})

    const MoveNode = (id: number, positions: number, parentId: string) => {
        return moveNode({
            variables: {
                id: id.toString(),
                positions,
                parentId
            }
        })
            .then(() => {
                setModelState({ ...modelState, hasChanges: !!rootNode?.is_edited })
            })
            .catch(() => {
                setErrorState({
                    hasError: true,
                    message: 'The node could not be moved, try again later.'
                })
            })
    }

    useEffect(() => {
        if (treeState && nodeData && selectedNodeId) {
            const updatedTree = updateTree(treeState, selectedNodeId, nodeData)
            if (updatedTree) {
                forceUpdate()
            }
        }
        // eslint-disable-next-line
    }, [nodeData, forceUpdate])

    useEffect(() => {
        if (rootNode) {
            // Make sure the tree expands when edited, else the tree would collapse constantly.
            const tree = modelState.hasEdited
                ? [formatTreeState(rootNode, true)]
                : [formatTreeState(rootNode)]
            setTreeState(tree)

            // If there are no changes, select the first node. This shows the form directly on model selection.
            !modelState.hasEdited && handleNodeClick(tree?.[0]?.id, 'null')

            setModelState({ ...modelState, updatedModel: false, hasChanges: !!rootNode?.is_edited })
        } else {
            handleNodeClick(0, 'null')
            setModelState({ ...modelState, currentModel: '' })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootNode]) // Disabled because it breaks the rendering of the TreeView

    if (!rootNode) {
        return <h3>Select a model to be loaded</h3>
    }

    return (
        <div className={classes.root}>
            <div className={classes.buttonGroup}>
                {rootNode?.id && (
                    <>
                        <Grid container direction="row">
                            <DuplicateModelButton id={rootNode.id} />
                            <UpdateAffectedPresetsButton id={rootNode.id} />
                            <ExportButton id={rootNode.id} />
                        </Grid>
                        <DownloadButton id={rootNode.id} />
                    </>
                )}
            </div>

            {treeState && (
                <SortableTree
                    isVirtualized={false}
                    scaffoldBlockPxWidth={30}
                    getNodeKey={({ node }) => node?.id}
                    treeData={treeState}
                    canDrop={({ node, nextParent }) => {
                        const nextParentType = nextParent?.node_attributes?.type

                        switch (node?.node_attributes?.type) {
                            case 'tab':
                                // Tabs can be ordered within the root
                                return nextParent?.id?.toString() === rootNode?.id?.toString()

                            case 'fieldset':
                                // Fieldsets can be moved to tabs
                                return nextParentType === 'tab'

                            case 'select':
                                // Selects can be moved to tabs or fieldsets
                                return nextParentType === 'tab' || nextParentType === 'fieldset'

                            case 'string':
                            case 'number':
                            case 'upload':
                            case 'content':
                                // Strings, numbers, uploads and content can be moved to tabs, fieldsets or selects
                                return (
                                    nextParentType === 'tab' ||
                                    nextParentType === 'fieldset' ||
                                    nextParentType === 'select'
                                )

                            case 'option':
                                // Options can be moved to selects
                                return nextParentType === 'select'

                            default:
                                return false
                        }
                    }}
                    onChange={(treeData) => setTreeState(treeData)}
                    generateNodeProps={(extendedNode) => {
                        const { title, node_attributes, id, parent_id } = extendedNode?.node
                        const label = node_attributes?.label
                        const type: string = node_attributes?.type || 'root'
                        let icon = <AccountTreeOutlinedIcon />
                        const types =
                            (id && type && getAvailableTypes(modelSchemaObject, type)) || []
                        const style = { color: grey[300], marginRight: '0.5em' }

                        switch (type) {
                            case 'tab':
                                icon = <VerticalSplitIcon style={style} />
                                break
                            case 'fieldset':
                                icon = <TabIcon style={style} />
                                break
                            case 'select':
                                icon = <ListIcon style={style} />
                                break
                            case 'string':
                                icon = <TextFormatOutlinedIcon style={style} />
                                break
                            case 'number':
                                icon = <DialpadOutlinedIcon style={style} />
                                break
                            case 'upload':
                                icon = <CloudUploadOutlinedIcon style={style} />
                                break
                            case 'option':
                                icon = <LabelOutlinedIcon style={style} />
                                break
                            case 'content':
                                icon = <ArtTrackOutlinedIcon style={style} />
                                break
                            case 'root':
                                icon = <AccountTreeOutlinedIcon style={style} />
                                break
                        }

                        const titleElement = (
                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                {icon} {label ? label + ` (${title})` : title}
                            </span>
                        )

                        return {
                            title: titleElement,
                            buttons: [
                                <RemoveButton
                                    key={id}
                                    className={classes.nodeButtons}
                                    onClick={() =>
                                        setRemovableItem({
                                            id: +id,
                                            parent_id: parent_id,
                                            extendedNode
                                        })
                                    }
                                />,
                                types.length > 0 || type === 'root' ? (
                                    <AddButton
                                        key={id}
                                        className={classes.nodeButtons}
                                        onClick={() =>
                                            setForm({
                                                id: id,
                                                rowInfo: extendedNode,
                                                open: true
                                            })
                                        }
                                    />
                                ) : null
                            ],
                            onClick: () => {
                                handleNodeClick(id, parent_id)
                                fetchNodeData(id)
                            }
                        }
                    }}
                    onMoveNode={({ prevTreeIndex, nextParentNode, treeIndex, node }): void => {
                        const positions = prevTreeIndex - treeIndex

                        MoveNode(node.id, positions, nextParentNode?.id)
                    }}
                />
            )}

            {removableItem && treeState && (
                <RemoveNodePopup
                    removableItem={removableItem}
                    treeState={treeState}
                    setTreeState={setTreeState}
                    setRemovableItem={setRemovableItem}
                />
            )}

            {treeState && (
                <AddNodeForm
                    id={form.id}
                    open={form.open}
                    rowInfo={form.rowInfo}
                    setForm={setForm}
                    treeState={treeState}
                    setTreeState={setTreeState}
                />
            )}
        </div>
    )
}

export default TreeView
