export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  configuratorDate: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-01-01 13:00:00`. */
  configuratorDateTime: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  configuratorUpload: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  packagesDate: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  packagesDateTime: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  personalizationDate: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  personalizationDateTime: any;
  /** A date String with format `Y-m-d`, e.g. `2011-05-23`. */
  retailersDate: any;
  /** A datetime String with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  retailersDateTime: any;
  /** Arbitrary data encoded in JavaScript Object Notation. See https://www.json.org. */
  retailersJSON: any;
  /**
   * Loose type that allows any value. Be careful when passing in large `Int` or `Float` literals,
   * as they may not be parsed correctly on the server side. Use `String` literals if you are
   * dealing with really large numbers to be on the safe side.
   */
  retailersMixed: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  retailersUpload: any;
};

export type ErrorArrayEntry = {
  __typename?: 'ErrorArrayEntry';
  extensions?: Maybe<Extensions>;
  locations?: Maybe<Array<Maybe<Locations>>>;
  message?: Maybe<Scalars['String']>;
  path?: Maybe<Array<Maybe<Scalars['String']>>>;
  trace?: Maybe<Array<Maybe<StackTrace>>>;
};

export type Extensions = {
  __typename?: 'Extensions';
  original_message?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  relativePath?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type IndexResult = {
  __typename?: 'IndexResult';
  created_at: Scalars['String'];
  retailer_id: Scalars['String'];
  status: Scalars['String'];
  updated_at: Scalars['String'];
};

export type Locations = {
  __typename?: 'Locations';
  column?: Maybe<Scalars['Int']>;
  line?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  configuratorBatchExportModelPresets: Array<Maybe<ConfiguratorPresetExportedItem>>;
  configuratorConfigurationUpdateSelection: Scalars['Boolean'];
  configuratorCreateNode?: Maybe<ConfiguratorNode>;
  configuratorCreatePreset?: Maybe<ConfiguratorPreset>;
  configuratorCreatePresetNode?: Maybe<ConfiguratorPresetNode>;
  configuratorDeleteConfiguration?: Maybe<Scalars['Boolean']>;
  configuratorDeleteNode?: Maybe<ConfiguratorNode>;
  configuratorDeletePreset?: Maybe<ConfiguratorPreset>;
  configuratorDeletePresetNode?: Maybe<ConfiguratorPresetNode>;
  configuratorDuplicateModel?: Maybe<Scalars['Int']>;
  configuratorDuplicatePreset?: Maybe<ConfiguratorPreset>;
  configuratorExportModel?: Maybe<ConfiguratorModel>;
  configuratorForgotPassword: ConfiguratorForgotPasswordResponse;
  configuratorImportJSONModel?: Maybe<ConfiguratorJsonImport>;
  configuratorLogin: ConfiguratorAuthPayload;
  configuratorLogout: ConfiguratorLogoutResponse;
  configuratorMakeDir?: Maybe<ConfiguratorMakeDirResult>;
  configuratorMoveNode?: Maybe<ConfiguratorNode>;
  configuratorRefreshToken: ConfiguratorAuthPayload;
  configuratorRegister: ConfiguratorAuthPayload;
  configuratorRemoveDir?: Maybe<Scalars['Boolean']>;
  configuratorRemoveFile?: Maybe<Scalars['Boolean']>;
  configuratorRemoveMyDesign?: Maybe<Scalars['Boolean']>;
  configuratorSaveConfiguration?: Maybe<ConfiguratorConfigurationResult>;
  configuratorSaveConfigurationSnapshots: Array<Maybe<ConfiguratorConfigurationGalleryItem>>;
  configuratorSaveMyDesign?: Maybe<Scalars['Boolean']>;
  configuratorUpdateForgottenPassword: ConfiguratorForgotPasswordResponse;
  configuratorUpdateMyDesign?: Maybe<Scalars['Boolean']>;
  configuratorUpdateNode?: Maybe<ConfiguratorNode>;
  configuratorUpdatePreset?: Maybe<ConfiguratorPreset>;
  configuratorUpdatePresetNode?: Maybe<ConfiguratorPresetNode>;
  configuratorUploadConfigurationMedia?: Maybe<Scalars['String']>;
  configuratorUploadModelMedia?: Maybe<ConfiguratorUploadModelMediaResult>;
  indexRetailer?: Maybe<IndexResult>;
  packagesCreatePackage?: Maybe<PackagesPackage>;
  packagesCreatePackageGalleryItem?: Maybe<PackagesPackageGalleryItem>;
  packagesCreatePackageGroup?: Maybe<PackagesPackageGroup>;
  packagesCreatePackageGroupCollection?: Maybe<PackagesPackageGroupCollection>;
  packagesCreatePackageGroupCollectionGalleryItem?: Maybe<PackagesPackageGroupCollectionGalleryItem>;
  packagesCreatePackageGroupGalleryItem?: Maybe<PackagesPackageGroupGalleryItem>;
  packagesCreatePackageGroupPackage?: Maybe<PackagesPackageGroupPackage>;
  packagesCreatePackageGroupPreset?: Maybe<PackagesPackageGroupPreset>;
  packagesCreatePackageProduct?: Maybe<PackagesPackageProduct>;
  packagesDeletePackage?: Maybe<PackagesPackage>;
  packagesDeletePackageGalleryItem?: Maybe<PackagesPackageGalleryItem>;
  packagesDeletePackageGroup?: Maybe<PackagesPackageGroup>;
  packagesDeletePackageGroupCollection?: Maybe<PackagesPackageGroupCollection>;
  packagesDeletePackageGroupCollectionGalleryItem?: Maybe<PackagesPackageGroupCollectionGalleryItem>;
  packagesDeletePackageGroupGalleryItem?: Maybe<PackagesPackageGroupGalleryItem>;
  packagesDeletePackageGroupPackage?: Maybe<Scalars['Boolean']>;
  packagesDeletePackageGroupPreset?: Maybe<PackagesPackageGroupPreset>;
  packagesDeletePackageProduct?: Maybe<PackagesPackageProduct>;
  packagesDuplicatePackage?: Maybe<PackagesPackage>;
  packagesDuplicatePackageGroup?: Maybe<PackagesPackageGroup>;
  packagesSavePackageSelection?: Maybe<PackagesSavedPackage>;
  packagesUpdatePackage?: Maybe<PackagesPackage>;
  packagesUpdatePackageGalleryItem?: Maybe<PackagesPackageGalleryItem>;
  packagesUpdatePackageGroup?: Maybe<PackagesPackageGroup>;
  packagesUpdatePackageGroupCollection?: Maybe<PackagesPackageGroupCollection>;
  packagesUpdatePackageGroupCollectionGalleryItem?: Maybe<PackagesPackageGroupCollectionGalleryItem>;
  packagesUpdatePackageGroupGalleryItem?: Maybe<PackagesPackageGroupGalleryItem>;
  packagesUpdatePackageGroupPackage?: Maybe<PackagesPackageGroupPreset>;
  packagesUpdatePackageGroupPreset?: Maybe<PackagesPackageGroupPreset>;
  packagesUpdatePackageProduct?: Maybe<PackagesPackageProduct>;
  personalizationCreateRule: PersonalizationRule;
  personalizationDeleteRule?: Maybe<PersonalizationRule>;
  personalizationDuplicateRule: PersonalizationRule;
  personalizationFlushActionsCache?: Maybe<Scalars['Boolean']>;
  personalizationFlushProductsCache?: Maybe<Scalars['Boolean']>;
  personalizationUpdateRule: PersonalizationRule;
  personalizationUpdateRulesPaths?: Maybe<Scalars['Boolean']>;
  retailersCreateEducation?: Maybe<RetailersEducation>;
  retailersCreateExtensionAttribute?: Maybe<RetailersExtensionAttribute>;
  retailersCreateRetailer?: Maybe<RetailersRetailer>;
  retailersCreateRetailerClass?: Maybe<RetailersRetailerClass>;
  retailersCreateRetailerClassRepresentative?: Maybe<RetailersRetailerClassRepresentative>;
  retailersCreateRetailerClassRetailerClassRepresentative?: Maybe<RetailersRetailerClassRetailerClassRepresentative>;
  retailersCreateRetailerClassRetailerTag?: Maybe<RetailersRetailerClassRetailerTag>;
  retailersCreateRetailerEducation?: Maybe<RetailersRetailerEducation>;
  retailersCreateRetailerPeriod?: Maybe<RetailersRetailerPeriod>;
  retailersCreateRetailerRetailerClassRepresentative?: Maybe<RetailersRetailerRetailerClassRepresentative>;
  retailersCreateRetailerRetailerTag?: Maybe<RetailersRetailerRetailerTag>;
  retailersCreateRetailerTag?: Maybe<RetailersRetailerTag>;
  retailersCreateRetailerYear?: Maybe<RetailersRetailerYear>;
  retailersDeleteEducation?: Maybe<RetailersEducation>;
  retailersDeleteExtensionAttribute?: Maybe<RetailersExtensionAttribute>;
  retailersDeleteRetailer?: Maybe<RetailersRetailer>;
  retailersDeleteRetailerClass?: Maybe<RetailersRetailerClass>;
  retailersDeleteRetailerClassRepresentative?: Maybe<RetailersRetailerClassRepresentative>;
  retailersDeleteRetailerClassRetailerClassRepresentative?: Maybe<RetailersRetailerClassRetailerClassRepresentative>;
  retailersDeleteRetailerClassRetailerTag?: Maybe<RetailersRetailerClassRetailerTag>;
  retailersDeleteRetailerEducation?: Maybe<RetailersRetailerEducation>;
  retailersDeleteRetailerPeriod?: Maybe<RetailersRetailerPeriod>;
  retailersDeleteRetailerRetailerClassRepresentative?: Maybe<RetailersRetailerRetailerClassRepresentative>;
  retailersDeleteRetailerRetailerTag?: Maybe<RetailersRetailerRetailerTag>;
  retailersDeleteRetailerTag?: Maybe<RetailersRetailerTag>;
  retailersDeleteRetailerYear?: Maybe<RetailersRetailerYear>;
  retailersImportRetailerClassRepresentatives?: Maybe<Scalars['Boolean']>;
  retailersImportRetailerClasses?: Maybe<Scalars['Boolean']>;
  retailersImportRetailers?: Maybe<Scalars['Boolean']>;
  retailersUpdateEducation?: Maybe<RetailersEducation>;
  retailersUpdateExtensionAttribute?: Maybe<RetailersExtensionAttribute>;
  retailersUpdateRetailer?: Maybe<RetailersRetailer>;
  retailersUpdateRetailerClass?: Maybe<RetailersRetailerClass>;
  retailersUpdateRetailerClassRepresentative?: Maybe<RetailersRetailerClassRepresentative>;
  retailersUpdateRetailerPeriod?: Maybe<RetailersRetailerPeriod>;
  retailersUpdateRetailerTag?: Maybe<RetailersRetailerTag>;
  retailersUpdateRetailerYear?: Maybe<RetailersRetailerYear>;
  singleUpload?: Maybe<UploadResponse>;
};


export type MutationConfiguratorBatchExportModelPresetsArgs = {
  model: Scalars['ID'];
};


export type MutationConfiguratorConfigurationUpdateSelectionArgs = {
  code: Scalars['ID'];
  path: Scalars['String'];
  selection: Scalars['String'];
};


export type MutationConfiguratorCreateNodeArgs = {
  node_attributes: Scalars['String'];
  parent_id?: InputMaybe<Scalars['Int']>;
};


export type MutationConfiguratorCreatePresetArgs = {
  active: Scalars['Boolean'];
  internal_name: Scalars['String'];
  parent_model_id: Scalars['Int'];
  public_name: Scalars['String'];
  published: Scalars['Boolean'];
};


export type MutationConfiguratorCreatePresetNodeArgs = {
  definition: Scalars['String'];
  parent_node_id: Scalars['Int'];
  preset_id: Scalars['Int'];
  removed?: InputMaybe<Scalars['Boolean']>;
};


export type MutationConfiguratorDeleteConfigurationArgs = {
  code: Scalars['ID'];
};


export type MutationConfiguratorDeleteNodeArgs = {
  id: Scalars['ID'];
};


export type MutationConfiguratorDeletePresetArgs = {
  id: Scalars['ID'];
};


export type MutationConfiguratorDeletePresetNodeArgs = {
  id: Scalars['ID'];
};


export type MutationConfiguratorDuplicateModelArgs = {
  destination: Scalars['String'];
  source: Scalars['Int'];
};


export type MutationConfiguratorDuplicatePresetArgs = {
  id: Scalars['ID'];
  internal_name: Scalars['String'];
  public_name: Scalars['String'];
};


export type MutationConfiguratorExportModelArgs = {
  id: Scalars['ID'];
  preset?: InputMaybe<Scalars['Int']>;
  upgrade_type?: InputMaybe<ConfiguratorSemanticVersion>;
};


export type MutationConfiguratorForgotPasswordArgs = {
  input: ConfiguratorForgotPasswordInput;
};


export type MutationConfiguratorImportJsonModelArgs = {
  json?: InputMaybe<Scalars['String']>;
};


export type MutationConfiguratorLoginArgs = {
  input?: InputMaybe<ConfiguratorLoginInput>;
};


export type MutationConfiguratorMakeDirArgs = {
  add: Scalars['String'];
  from?: InputMaybe<Scalars['String']>;
};


export type MutationConfiguratorMoveNodeArgs = {
  id: Scalars['ID'];
  parentId?: InputMaybe<Scalars['ID']>;
  positions?: InputMaybe<Scalars['Int']>;
};


export type MutationConfiguratorRefreshTokenArgs = {
  input?: InputMaybe<ConfiguratorRefreshTokenInput>;
};


export type MutationConfiguratorRegisterArgs = {
  input?: InputMaybe<ConfiguratorRegisterInput>;
};


export type MutationConfiguratorRemoveDirArgs = {
  from?: InputMaybe<Scalars['String']>;
  remove: Scalars['String'];
};


export type MutationConfiguratorRemoveFileArgs = {
  from?: InputMaybe<Scalars['String']>;
  remove: Scalars['String'];
};


export type MutationConfiguratorRemoveMyDesignArgs = {
  configuration_code: Scalars['String'];
  customer_id: Scalars['Int'];
};


export type MutationConfiguratorSaveConfigurationArgs = {
  configuration: Scalars['String'];
  model?: InputMaybe<Scalars['String']>;
  overrides?: InputMaybe<Array<InputMaybe<ConfiguratorModelPathOverride>>>;
  renderStack: Scalars['String'];
  shallow?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['String']>;
};


export type MutationConfiguratorSaveConfigurationSnapshotsArgs = {
  code: Scalars['String'];
  files?: InputMaybe<Array<InputMaybe<ConfiguratorConfigurationGalleryItemInput>>>;
};


export type MutationConfiguratorSaveMyDesignArgs = {
  configuration_code: Scalars['String'];
  customer_id: Scalars['Int'];
  ecommerce_data: ConfiguratorEcommerceDataParameter;
};


export type MutationConfiguratorUpdateForgottenPasswordArgs = {
  input: ConfiguratorNewPasswordWithCodeInput;
};


export type MutationConfiguratorUpdateMyDesignArgs = {
  customer_id: Scalars['Int'];
  new_code: Scalars['String'];
  old_code: Scalars['String'];
};


export type MutationConfiguratorUpdateNodeArgs = {
  id: Scalars['ID'];
  node_attributes?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['Int']>;
};


export type MutationConfiguratorUpdatePresetArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  internal_name?: InputMaybe<Scalars['String']>;
  public_name?: InputMaybe<Scalars['String']>;
};


export type MutationConfiguratorUpdatePresetNodeArgs = {
  definition?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  removed?: InputMaybe<Scalars['Boolean']>;
};


export type MutationConfiguratorUploadConfigurationMediaArgs = {
  base64?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['configuratorUpload']>;
  jsonpath: Scalars['String'];
  model: Scalars['String'];
};


export type MutationConfiguratorUploadModelMediaArgs = {
  file: Scalars['configuratorUpload'];
  fileName: Scalars['String'];
  path: Scalars['String'];
};


export type MutationIndexRetailerArgs = {
  retailerId: Scalars['String'];
};


export type MutationPackagesCreatePackageArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  ajat_id: Scalars['String'];
  base_price?: InputMaybe<Scalars['Float']>;
  definition: Scalars['String'];
  final_price: Scalars['Float'];
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  overlay_text?: InputMaybe<Scalars['String']>;
  overlay_text_bottom?: InputMaybe<Scalars['String']>;
  price_tooltip?: InputMaybe<Scalars['String']>;
  public_name: Scalars['String'];
  short_description?: InputMaybe<Scalars['String']>;
  type: PackagesPackageType;
  wp_slug?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesCreatePackageGalleryItemArgs = {
  alt: Scalars['String'];
  package_id: Scalars['Int'];
  path: Scalars['String'];
  title: Scalars['String'];
};


export type MutationPackagesCreatePackageGroupArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  base_price?: InputMaybe<Scalars['Float']>;
  collection_id: Scalars['ID'];
  final_price: Scalars['Float'];
  internal_name: Scalars['String'];
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  is_free_design?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  overlay_text?: InputMaybe<Scalars['String']>;
  price_tooltip?: InputMaybe<Scalars['String']>;
  public_name: Scalars['String'];
  short_description?: InputMaybe<Scalars['String']>;
  usps?: InputMaybe<Scalars['String']>;
  wp_slug?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesCreatePackageGroupCollectionArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  internal_name: Scalars['String'];
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  public_name: Scalars['String'];
  short_description?: InputMaybe<Scalars['String']>;
  usps?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesCreatePackageGroupCollectionGalleryItemArgs = {
  alt: Scalars['String'];
  collection_id: Scalars['Int'];
  path: Scalars['String'];
  title: Scalars['String'];
};


export type MutationPackagesCreatePackageGroupGalleryItemArgs = {
  alt: Scalars['String'];
  group_id: Scalars['Int'];
  path: Scalars['String'];
  title: Scalars['String'];
};


export type MutationPackagesCreatePackageGroupPackageArgs = {
  group_id: Scalars['Int'];
  package_id: Scalars['Int'];
};


export type MutationPackagesCreatePackageGroupPresetArgs = {
  code: Scalars['String'];
  group_id: Scalars['Int'];
  sku: Scalars['String'];
};


export type MutationPackagesCreatePackageProductArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  comparison_label?: InputMaybe<Scalars['String']>;
  definition?: InputMaybe<Scalars['String']>;
  included_options?: InputMaybe<Scalars['String']>;
  is_included?: InputMaybe<Scalars['Boolean']>;
  is_main?: InputMaybe<Scalars['Boolean']>;
  package_id: Scalars['Int'];
  preset_code?: InputMaybe<Scalars['String']>;
  root_model?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  sku: Scalars['String'];
  type: PackagesPackageProductType;
};


export type MutationPackagesDeletePackageArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageGalleryItemArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageGroupArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageGroupCollectionArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageGroupCollectionGalleryItemArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageGroupGalleryItemArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageGroupPackageArgs = {
  group_id?: InputMaybe<Scalars['Int']>;
  package_id?: InputMaybe<Scalars['Int']>;
};


export type MutationPackagesDeletePackageGroupPresetArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageProductArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDuplicatePackageArgs = {
  ajat_id: Scalars['String'];
  id: Scalars['ID'];
  public_name: Scalars['String'];
};


export type MutationPackagesDuplicatePackageGroupArgs = {
  id: Scalars['ID'];
  internal_name: Scalars['String'];
  public_name: Scalars['String'];
};


export type MutationPackagesSavePackageSelectionArgs = {
  code?: InputMaybe<Scalars['String']>;
  selection: Scalars['String'];
};


export type MutationPackagesUpdatePackageArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  ajat_id?: InputMaybe<Scalars['String']>;
  base_price?: InputMaybe<Scalars['Float']>;
  definition?: InputMaybe<Scalars['String']>;
  final_price?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  overlay_text?: InputMaybe<Scalars['String']>;
  overlay_text_bottom?: InputMaybe<Scalars['String']>;
  price_tooltip?: InputMaybe<Scalars['String']>;
  public_name?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  wp_slug?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageGalleryItemArgs = {
  alt?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  package_id?: InputMaybe<Scalars['Int']>;
  path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageGroupArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  base_price?: InputMaybe<Scalars['Float']>;
  collection_id?: InputMaybe<Scalars['ID']>;
  final_price?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  internal_name?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  is_free_design?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  overlay_text?: InputMaybe<Scalars['String']>;
  price_tooltip?: InputMaybe<Scalars['String']>;
  public_name?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  usps?: InputMaybe<Scalars['String']>;
  wp_slug?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageGroupCollectionArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  internal_name?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  public_name?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  usps?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageGroupCollectionGalleryItemArgs = {
  alt?: InputMaybe<Scalars['String']>;
  collection_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageGroupGalleryItemArgs = {
  alt?: InputMaybe<Scalars['String']>;
  group_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageGroupPackageArgs = {
  group_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  package_id?: InputMaybe<Scalars['Int']>;
};


export type MutationPackagesUpdatePackageGroupPresetArgs = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  sku?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageProductArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  comparison_label?: InputMaybe<Scalars['String']>;
  definition?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  included_options?: InputMaybe<Scalars['String']>;
  is_included?: InputMaybe<Scalars['Boolean']>;
  is_main?: InputMaybe<Scalars['Boolean']>;
  preset_code?: InputMaybe<Scalars['String']>;
  root_model?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PackagesPackageProductType>;
};


export type MutationPersonalizationCreateRuleArgs = {
  actions: Scalars['String'];
  active?: InputMaybe<Scalars['Boolean']>;
  break?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  rules: Scalars['String'];
};


export type MutationPersonalizationDeleteRuleArgs = {
  id: Scalars['ID'];
};


export type MutationPersonalizationDuplicateRuleArgs = {
  id: Scalars['ID'];
};


export type MutationPersonalizationUpdateRuleArgs = {
  actions?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  break?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  label?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  rules?: InputMaybe<Scalars['String']>;
};


export type MutationPersonalizationUpdateRulesPathsArgs = {
  updates: Array<PersonalizationRulePathUpdateInput>;
};


export type MutationRetailersCreateEducationArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  code: Scalars['String'];
  name: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
};


export type MutationRetailersCreateExtensionAttributeArgs = {
  attribute_backend_type: Scalars['String'];
  attribute_code: Scalars['String'];
  attribute_frontend_type: Scalars['String'];
  attribute_label: Scalars['String'];
  entity_type: Scalars['String'];
};


export type MutationRetailersCreateRetailerArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  embroidery_1?: InputMaybe<Scalars['String']>;
  embroidery_2?: InputMaybe<Scalars['String']>;
  embroidery_3?: InputMaybe<Scalars['String']>;
  extension_attributes?: InputMaybe<Scalars['retailersJSON']>;
  hero_banner?: InputMaybe<Scalars['String']>;
  internal_note?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logo_2?: InputMaybe<Scalars['String']>;
  logo_3?: InputMaybe<Scalars['String']>;
  logo_4?: InputMaybe<Scalars['String']>;
  marketing_url?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  personalization_logo?: InputMaybe<Scalars['String']>;
  post_code?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  representative_discount_amount?: InputMaybe<Scalars['Float']>;
  representative_discount_percentage?: InputMaybe<Scalars['Float']>;
  representative_email?: InputMaybe<Scalars['String']>;
  representative_name?: InputMaybe<Scalars['String']>;
  representative_ssn?: InputMaybe<Scalars['String']>;
  representative_telephone?: InputMaybe<Scalars['String']>;
  sales_responsible?: InputMaybe<Scalars['String']>;
  sales_team?: InputMaybe<Scalars['String']>;
  students_amount?: InputMaybe<Scalars['Int']>;
};


export type MutationRetailersCreateRetailerClassArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  embroidery_1?: InputMaybe<Scalars['String']>;
  embroidery_2?: InputMaybe<Scalars['String']>;
  embroidery_3?: InputMaybe<Scalars['String']>;
  extension_attributes?: InputMaybe<Scalars['retailersJSON']>;
  name: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  retailer_id: Scalars['Int'];
  retailer_year_id: Scalars['Int'];
  students_amount?: InputMaybe<Scalars['Int']>;
};


export type MutationRetailersCreateRetailerClassRepresentativeArgs = {
  discount_amount?: InputMaybe<Scalars['Float']>;
  discount_percentage?: InputMaybe<Scalars['Float']>;
  discount_used?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  name: Scalars['String'];
  ssn?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationRetailersCreateRetailerClassRetailerClassRepresentativeArgs = {
  representative_id: Scalars['Int'];
  retailer_class_id: Scalars['Int'];
};


export type MutationRetailersCreateRetailerClassRetailerTagArgs = {
  retailer_class_id: Scalars['Int'];
  retailer_tag_id: Scalars['Int'];
};


export type MutationRetailersCreateRetailerEducationArgs = {
  education_id: Scalars['Int'];
  retailer_id: Scalars['Int'];
};


export type MutationRetailersCreateRetailerPeriodArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  from_date: Scalars['retailersDate'];
  grace_payment_date?: InputMaybe<Scalars['retailersDate']>;
  retailer_id: Scalars['Int'];
  to_date: Scalars['retailersDate'];
};


export type MutationRetailersCreateRetailerRetailerClassRepresentativeArgs = {
  representative_id: Scalars['Int'];
  retailer_id: Scalars['Int'];
};


export type MutationRetailersCreateRetailerRetailerTagArgs = {
  retailer_id: Scalars['Int'];
  retailer_tag_id: Scalars['Int'];
};


export type MutationRetailersCreateRetailerTagArgs = {
  tag: Scalars['String'];
};


export type MutationRetailersCreateRetailerYearArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationRetailersDeleteEducationArgs = {
  id: Scalars['ID'];
};


export type MutationRetailersDeleteExtensionAttributeArgs = {
  id: Scalars['ID'];
};


export type MutationRetailersDeleteRetailerArgs = {
  id: Scalars['ID'];
};


export type MutationRetailersDeleteRetailerClassArgs = {
  id: Scalars['ID'];
};


export type MutationRetailersDeleteRetailerClassRepresentativeArgs = {
  id: Scalars['ID'];
};


export type MutationRetailersDeleteRetailerClassRetailerClassRepresentativeArgs = {
  representative_id: Scalars['Int'];
  retailer_class_id: Scalars['Int'];
};


export type MutationRetailersDeleteRetailerClassRetailerTagArgs = {
  retailer_class_id: Scalars['Int'];
  retailer_tag_id: Scalars['Int'];
};


export type MutationRetailersDeleteRetailerEducationArgs = {
  education_id: Scalars['Int'];
  retailer_id: Scalars['Int'];
};


export type MutationRetailersDeleteRetailerPeriodArgs = {
  id: Scalars['ID'];
};


export type MutationRetailersDeleteRetailerRetailerClassRepresentativeArgs = {
  representative_id: Scalars['Int'];
  retailer_id: Scalars['Int'];
};


export type MutationRetailersDeleteRetailerRetailerTagArgs = {
  retailer_id: Scalars['Int'];
  retailer_tag_id: Scalars['Int'];
};


export type MutationRetailersDeleteRetailerTagArgs = {
  id: Scalars['ID'];
};


export type MutationRetailersDeleteRetailerYearArgs = {
  id: Scalars['ID'];
};


export type MutationRetailersImportRetailerClassRepresentativesArgs = {
  file: Scalars['retailersUpload'];
};


export type MutationRetailersImportRetailerClassesArgs = {
  file: Scalars['retailersUpload'];
};


export type MutationRetailersImportRetailersArgs = {
  file: Scalars['retailersUpload'];
};


export type MutationRetailersUpdateEducationArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
};


export type MutationRetailersUpdateExtensionAttributeArgs = {
  attribute_backend_type?: InputMaybe<Scalars['String']>;
  attribute_code?: InputMaybe<Scalars['String']>;
  attribute_frontend_type?: InputMaybe<Scalars['String']>;
  attribute_label?: InputMaybe<Scalars['String']>;
  entity_type?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationRetailersUpdateRetailerArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  embroidery_1?: InputMaybe<Scalars['String']>;
  embroidery_2?: InputMaybe<Scalars['String']>;
  embroidery_3?: InputMaybe<Scalars['String']>;
  extension_attributes?: InputMaybe<Scalars['retailersJSON']>;
  hero_banner?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  internal_note?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logo_2?: InputMaybe<Scalars['String']>;
  logo_3?: InputMaybe<Scalars['String']>;
  logo_4?: InputMaybe<Scalars['String']>;
  marketing_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  personalization_logo?: InputMaybe<Scalars['String']>;
  post_code?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  representative_discount_amount?: InputMaybe<Scalars['Float']>;
  representative_discount_percentage?: InputMaybe<Scalars['Float']>;
  representative_email?: InputMaybe<Scalars['String']>;
  representative_name?: InputMaybe<Scalars['String']>;
  representative_ssn?: InputMaybe<Scalars['String']>;
  representative_telephone?: InputMaybe<Scalars['String']>;
  sales_responsible?: InputMaybe<Scalars['String']>;
  sales_team?: InputMaybe<Scalars['String']>;
  students_amount?: InputMaybe<Scalars['Int']>;
};


export type MutationRetailersUpdateRetailerClassArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  embroidery_1?: InputMaybe<Scalars['String']>;
  embroidery_2?: InputMaybe<Scalars['String']>;
  embroidery_3?: InputMaybe<Scalars['String']>;
  extension_attributes?: InputMaybe<Scalars['retailersJSON']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  retailer_id?: InputMaybe<Scalars['Int']>;
  retailer_year_id?: InputMaybe<Scalars['Int']>;
  students_amount?: InputMaybe<Scalars['Int']>;
};


export type MutationRetailersUpdateRetailerClassRepresentativeArgs = {
  discount_amount?: InputMaybe<Scalars['Float']>;
  discount_percentage?: InputMaybe<Scalars['Float']>;
  discount_used?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationRetailersUpdateRetailerPeriodArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  from_date: Scalars['retailersDate'];
  grace_payment_date?: InputMaybe<Scalars['retailersDate']>;
  id: Scalars['ID'];
  to_date: Scalars['retailersDate'];
};


export type MutationRetailersUpdateRetailerTagArgs = {
  id: Scalars['ID'];
  tag: Scalars['String'];
};


export type MutationRetailersUpdateRetailerYearArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationSingleUploadArgs = {
  file: Scalars['Upload'];
  fileName: Scalars['String'];
  path: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  configuratorCameraPresets?: Maybe<ConfiguratorCameraPresetResults>;
  configuratorConfiguration?: Maybe<ConfiguratorConfigurationResult>;
  configuratorConfigurationFilter?: Maybe<ConfiguratorConfigurationFilterResult>;
  configuratorConfigurationHasRequiredSelections?: Maybe<ConfiguratorConfigurationHasRequiredSelectionResult>;
  configuratorConfigurationHasSelection?: Maybe<ConfiguratorConfigurationHasSelectionResult>;
  configuratorDefaultConfigurationByPreset?: Maybe<Scalars['String']>;
  configuratorExportJSONModel?: Maybe<ConfiguratorJsonExport>;
  configuratorFlattenConfiguration?: Maybe<Scalars['String']>;
  configuratorGalleryMediaUrl?: Maybe<Scalars['String']>;
  configuratorGalleryStructure?: Maybe<Scalars['String']>;
  configuratorGetAttributesTreeByModelNode?: Maybe<Scalars['String']>;
  configuratorGetAttributesTreeByModelNodeType?: Maybe<Scalars['String']>;
  configuratorGetMyDesignByConfiguration?: Maybe<Array<Maybe<ConfiguratorMyDesign>>>;
  configuratorIsMyDesign?: Maybe<Scalars['Boolean']>;
  configuratorModel?: Maybe<ConfiguratorModel>;
  configuratorModelMedia?: Maybe<Scalars['String']>;
  configuratorModelSchema?: Maybe<Scalars['String']>;
  configuratorModelTreeStructure?: Maybe<Scalars['String']>;
  configuratorNode?: Maybe<ConfiguratorNode>;
  configuratorNodeOverrideDryRun?: Maybe<Scalars['String']>;
  configuratorNodeOverrideSchema?: Maybe<Scalars['String']>;
  configuratorNodes: Array<ConfiguratorNode>;
  configuratorPreset?: Maybe<ConfiguratorPreset>;
  configuratorPresetNode?: Maybe<ConfiguratorPresetNode>;
  configuratorPresetNodes?: Maybe<ConfiguratorPresetNodePaginator>;
  configuratorPresets?: Maybe<ConfiguratorPresetPaginator>;
  configuratorRenderStack?: Maybe<ConfiguratorRenderStackResult>;
  configuratorUiSchema?: Maybe<Scalars['String']>;
  configuratorUser?: Maybe<ConfiguratorUser>;
  configuratorUsers?: Maybe<ConfiguratorUserPaginator>;
  configuratorValidateConfiguration?: Maybe<Scalars['Boolean']>;
  otherFields: Scalars['Boolean'];
  packagesAvailableProducts?: Maybe<Array<Maybe<PackagesAvailableProduct>>>;
  packagesGetMediaUrl?: Maybe<Scalars['String']>;
  packagesPackage?: Maybe<PackagesPackage>;
  packagesPackageGalleryItem?: Maybe<PackagesPackageGalleryItem>;
  packagesPackageGalleryItems?: Maybe<PackagesPackageGalleryItemPaginator>;
  packagesPackageGroup?: Maybe<PackagesPackageGroup>;
  packagesPackageGroupCollection?: Maybe<PackagesPackageGroupCollection>;
  packagesPackageGroupCollectionGalleryItem?: Maybe<PackagesPackageGroupCollectionGalleryItem>;
  packagesPackageGroupCollectionGalleryItems?: Maybe<PackagesPackageGroupCollectionGalleryItemPaginator>;
  packagesPackageGroupCollections?: Maybe<PackagesPackageGroupCollectionPaginator>;
  packagesPackageGroupGalleryItem?: Maybe<PackagesPackageGroupGalleryItem>;
  packagesPackageGroupGalleryItems?: Maybe<PackagesPackageGroupGalleryItemPaginator>;
  packagesPackageGroups?: Maybe<PackagesPackageGroupPaginator>;
  packagesPackageProduct?: Maybe<PackagesPackageProduct>;
  packagesPackageProducts?: Maybe<PackagesPackageProductPaginator>;
  packagesPackageSchema?: Maybe<Scalars['String']>;
  packagesPackageSelection?: Maybe<PackagesSavedPackage>;
  packagesPackageSelections?: Maybe<Array<Maybe<PackagesSavedPackage>>>;
  packagesPackages?: Maybe<PackagesPackagePaginator>;
  packagesValidateSelection?: Maybe<Scalars['Boolean']>;
  personalizationActionConfig?: Maybe<Scalars['String']>;
  personalizationAvailableMentions?: Maybe<Scalars['String']>;
  personalizationProcessRules?: Maybe<Scalars['String']>;
  personalizationRule?: Maybe<PersonalizationRule>;
  personalizationRuleBuilderConfig?: Maybe<Scalars['String']>;
  personalizationRules?: Maybe<PersonalizationRulePaginator>;
  retailersCountries?: Maybe<Array<Maybe<RetailersCountry>>>;
  retailersEducation?: Maybe<RetailersEducation>;
  retailersEducations?: Maybe<RetailersEducationPaginator>;
  retailersExtensionAttribute?: Maybe<RetailersExtensionAttribute>;
  retailersExtensionAttributes?: Maybe<RetailersExtensionAttributePaginator>;
  retailersExtensionAttributesSchema?: Maybe<Scalars['String']>;
  retailersGalleryMediaUrl?: Maybe<Scalars['String']>;
  retailersRetailer?: Maybe<RetailersRetailer>;
  retailersRetailerByCode?: Maybe<RetailersRetailer>;
  retailersRetailerClass?: Maybe<RetailersRetailerClass>;
  retailersRetailerClassByCodes?: Maybe<RetailersRetailerClass>;
  retailersRetailerClassRepresentative?: Maybe<RetailersRetailerClassRepresentative>;
  retailersRetailerClassRepresentatives?: Maybe<RetailersRetailerClassRepresentativePaginator>;
  retailersRetailerClassRetailerTags?: Maybe<RetailersRetailerClassRetailerTagPaginator>;
  retailersRetailerClasses?: Maybe<RetailersRetailerClassPaginator>;
  retailersRetailerEducations?: Maybe<RetailersRetailerEducationPaginator>;
  retailersRetailerPeriod?: Maybe<RetailersRetailerPeriod>;
  retailersRetailerPeriods?: Maybe<RetailersRetailerPeriodPaginator>;
  retailersRetailerRetailerTags?: Maybe<RetailersRetailerRetailerTagPaginator>;
  retailersRetailerSalesResponsibles?: Maybe<RetailersRetailerSalesResponsiblePaginator>;
  retailersRetailerSalesTeams?: Maybe<RetailersRetailerSalesTeamPaginator>;
  retailersRetailerTag?: Maybe<RetailersRetailerTag>;
  retailersRetailerTags?: Maybe<RetailersRetailerTagPaginator>;
  retailersRetailerYear?: Maybe<RetailersRetailerYear>;
  retailersRetailerYears?: Maybe<RetailersRetailerYearPaginator>;
  retailersRetailers?: Maybe<RetailersRetailerPaginator>;
  roles?: Maybe<Array<Role>>;
};


export type QueryConfiguratorCameraPresetsArgs = {
  code?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorConfigurationArgs = {
  code: Scalars['ID'];
  shallow?: InputMaybe<Scalars['Boolean']>;
};


export type QueryConfiguratorConfigurationFilterArgs = {
  filter?: InputMaybe<Array<InputMaybe<ConfiguratorConfigurationFilter>>>;
  from?: InputMaybe<Scalars['Int']>;
  modelCode?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['Float']>;
};


export type QueryConfiguratorConfigurationHasRequiredSelectionsArgs = {
  code: Scalars['ID'];
};


export type QueryConfiguratorConfigurationHasSelectionArgs = {
  code: Scalars['ID'];
  path: Scalars['String'];
};


export type QueryConfiguratorDefaultConfigurationByPresetArgs = {
  code: Scalars['String'];
  overrides?: InputMaybe<Array<InputMaybe<ConfiguratorModelPathOverride>>>;
  version?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorExportJsonModelArgs = {
  include_presets?: InputMaybe<Scalars['Boolean']>;
  node_id: Scalars['ID'];
};


export type QueryConfiguratorFlattenConfigurationArgs = {
  code: Scalars['ID'];
};


export type QueryConfiguratorGalleryMediaUrlArgs = {
  rel?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorGalleryStructureArgs = {
  from?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorGetAttributesTreeByModelNodeArgs = {
  model: Scalars['String'];
  modelNode: Scalars['String'];
};


export type QueryConfiguratorGetAttributesTreeByModelNodeTypeArgs = {
  modelNodeType: Scalars['String'];
};


export type QueryConfiguratorGetMyDesignByConfigurationArgs = {
  configuration_code: Scalars['String'];
};


export type QueryConfiguratorIsMyDesignArgs = {
  configuration_code: Scalars['String'];
  customer_id: Scalars['Int'];
};


export type QueryConfiguratorModelArgs = {
  code: Scalars['ID'];
  market?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  overrides?: InputMaybe<Array<InputMaybe<ConfiguratorModelPathOverride>>>;
  version?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorModelMediaArgs = {
  code: Scalars['ID'];
  version: Scalars['String'];
};


export type QueryConfiguratorModelSchemaArgs = {
  model?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorModelTreeStructureArgs = {
  code: Scalars['ID'];
};


export type QueryConfiguratorNodeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfiguratorNodeOverrideDryRunArgs = {
  node_id: Scalars['Int'];
};


export type QueryConfiguratorNodeOverrideSchemaArgs = {
  model: Scalars['String'];
  node_type: ConfiguratorNodeType;
};


export type QueryConfiguratorNodesArgs = {
  parent_id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfiguratorPresetArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfiguratorPresetNodeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfiguratorPresetNodesArgs = {
  filter?: InputMaybe<ConfiguratorPresetNodeFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConfiguratorPresetNodesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryConfiguratorPresetsArgs = {
  filter?: InputMaybe<ConfiguratorPresetFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConfiguratorPresetsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryConfiguratorRenderStackArgs = {
  configurationCode: Scalars['ID'];
};


export type QueryConfiguratorUiSchemaArgs = {
  model?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfiguratorUsersArgs = {
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryConfiguratorValidateConfigurationArgs = {
  configuration: Scalars['String'];
  model: Scalars['String'];
  overrides?: InputMaybe<Array<InputMaybe<ConfiguratorModelPathOverride>>>;
};


export type QueryPackagesGetMediaUrlArgs = {
  rel: Scalars['String'];
};


export type QueryPackagesPackageArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageGalleryItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageGalleryItemsArgs = {
  filter?: InputMaybe<PackagesPackageGalleryItemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackageGalleryItemsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesPackageGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageGroupCollectionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageGroupCollectionGalleryItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageGroupCollectionGalleryItemsArgs = {
  filter?: InputMaybe<PackagesPackageGroupCollectionGalleryItemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackageGroupCollectionGalleryItemsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesPackageGroupCollectionsArgs = {
  filter?: InputMaybe<PackagesPackageGroupCollectionFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackageGroupCollectionsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesPackageGroupGalleryItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageGroupGalleryItemsArgs = {
  filter?: InputMaybe<PackagesPackageGroupGalleryItemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackageGroupGalleryItemsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesPackageGroupsArgs = {
  filter?: InputMaybe<PackagesPackageGroupFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackageGroupsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesPackageProductArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageProductsArgs = {
  filter?: InputMaybe<PackagesPackageProductFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackageProductsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesPackageSchemaArgs = {
  type: PackagesPackageType;
};


export type QueryPackagesPackageSelectionArgs = {
  code: Scalars['String'];
};


export type QueryPackagesPackageSelectionsArgs = {
  ids: Array<InputMaybe<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<PackagesOrderByDirection>;
};


export type QueryPackagesPackagesArgs = {
  filter?: InputMaybe<PackagesPackageFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackagesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesValidateSelectionArgs = {
  code: Scalars['String'];
};


export type QueryPersonalizationAvailableMentionsArgs = {
  type?: InputMaybe<PersonalizationAvailableMentionsTypes>;
  withEntity?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPersonalizationProcessRulesArgs = {
  data: Scalars['String'];
};


export type QueryPersonalizationRuleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPersonalizationRulesArgs = {
  filter?: InputMaybe<PersonalizationRuleFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PersonalizationQueryRulesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRetailersEducationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailersEducationsArgs = {
  filter?: InputMaybe<RetailersEducationFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RetailersQueryEducationsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRetailersExtensionAttributeArgs = {
  id: Scalars['ID'];
};


export type QueryRetailersExtensionAttributesArgs = {
  filter?: InputMaybe<RetailersExtensionAttributeFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RetailersQueryExtensionAttributesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRetailersExtensionAttributesSchemaArgs = {
  entity_type: Scalars['String'];
};


export type QueryRetailersGalleryMediaUrlArgs = {
  rel?: InputMaybe<Scalars['String']>;
};


export type QueryRetailersRetailerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailersRetailerByCodeArgs = {
  code: Scalars['String'];
};


export type QueryRetailersRetailerClassArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailersRetailerClassByCodesArgs = {
  class_code: Scalars['String'];
  retailer?: InputMaybe<RetailersQueryRetailerClassByCodesRetailerWhereHasConditions>;
};


export type QueryRetailersRetailerClassRepresentativeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailersRetailerClassRepresentativesArgs = {
  filter?: InputMaybe<RetailersRetailerClassRepresentativeFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RetailersQueryRetailerClassRepresentativesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RetailersQueryRetailerClassRepresentativesWhereWhereConditions>;
};


export type QueryRetailersRetailerClassRetailerTagsArgs = {
  filter?: InputMaybe<RetailersRetailerClassRetailerTagFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RetailersQueryRetailerClassRetailerTagsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRetailersRetailerClassesArgs = {
  filter?: InputMaybe<RetailersRetailerClassFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RetailersQueryRetailerClassesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RetailersQueryRetailerClassesWhereWhereConditions>;
};


export type QueryRetailersRetailerEducationsArgs = {
  filter?: InputMaybe<RetailersRetailerEducationFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RetailersQueryRetailerEducationsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRetailersRetailerPeriodArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailersRetailerPeriodsArgs = {
  filter?: InputMaybe<RetailersRetailerPeriodFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RetailersQueryRetailerPeriodsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRetailersRetailerRetailerTagsArgs = {
  filter?: InputMaybe<RetailersRetailerRetailerTagFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RetailersQueryRetailerRetailerTagsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRetailersRetailerSalesResponsiblesArgs = {
  filter?: InputMaybe<RetailersRetailerSalesResponsibleFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RetailersQueryRetailerSalesResponsiblesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RetailersQueryRetailerSalesResponsiblesWhereWhereConditions>;
};


export type QueryRetailersRetailerSalesTeamsArgs = {
  filter?: InputMaybe<RetailersRetailerSalesTeamFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RetailersQueryRetailerSalesTeamsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RetailersQueryRetailerSalesTeamsWhereWhereConditions>;
};


export type QueryRetailersRetailerTagArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailersRetailerTagsArgs = {
  filter?: InputMaybe<RetailersRetailerTagFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RetailersQueryRetailerTagsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRetailersRetailerYearArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailersRetailerYearsArgs = {
  filter?: InputMaybe<RetailersRetailerYearFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RetailersQueryRetailerYearsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRetailersRetailersArgs = {
  filter?: InputMaybe<RetailersRetailerFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RetailersQueryRetailersOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RetailersQueryRetailersWhereWhereConditions>;
};


export type QueryRolesArgs = {
  user_id: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type StackTrace = {
  __typename?: 'StackTrace';
  call?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['Int']>;
};

export type UploadData = {
  __typename?: 'UploadData';
  uploadModelMedia?: Maybe<File>;
};

export type UploadResponse = {
  __typename?: 'UploadResponse';
  data?: Maybe<UploadData>;
  errors?: Maybe<Array<Maybe<ErrorArrayEntry>>>;
};

export type ConfiguratorAuthPayload = {
  __typename?: 'configuratorAuthPayload';
  access_token: Scalars['String'];
  expires_in: Scalars['Int'];
  refresh_token: Scalars['String'];
  token_type: Scalars['String'];
  user: ConfiguratorUser;
};

export type ConfiguratorCameraPresetResult = {
  __typename?: 'configuratorCameraPresetResult';
  dolly?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  snapshotParams?: Maybe<ConfiguratorCameraPresetResultSnapshotParams>;
  useInCameraBar?: Maybe<Scalars['Boolean']>;
  useToSwitch?: Maybe<Scalars['Boolean']>;
  xang?: Maybe<Scalars['Float']>;
  xpan?: Maybe<Scalars['Float']>;
  yang?: Maybe<Scalars['Float']>;
  ypan?: Maybe<Scalars['Float']>;
};

export type ConfiguratorCameraPresetResultSnapshotParams = {
  __typename?: 'configuratorCameraPresetResultSnapshotParams';
  excludeNodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  includeNodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ConfiguratorCameraPresetResults = {
  __typename?: 'configuratorCameraPresetResults';
  defaultSnapshotCode?: Maybe<Scalars['String']>;
  defaultSnapshotParams?: Maybe<ConfiguratorCameraPresetResultSnapshotParams>;
  presets?: Maybe<Array<Maybe<ConfiguratorCameraPresetResult>>>;
};

export type ConfiguratorConfigurationDetailGroup = {
  __typename?: 'configuratorConfigurationDetailGroup';
  code?: Maybe<Scalars['String']>;
  configurations?: Maybe<Array<Maybe<ConfiguratorConfigurationDetailGroupSelection>>>;
  icon?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  pricing_info?: Maybe<ConfiguratorDetailPricingInfo>;
};

export type ConfiguratorConfigurationDetailGroupSelection = {
  __typename?: 'configuratorConfigurationDetailGroupSelection';
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  pricing_info?: Maybe<ConfiguratorDetailPricingInfo>;
  value?: Maybe<Scalars['String']>;
};

export type ConfiguratorConfigurationFilter = {
  code: Scalars['String'];
  value: Scalars['String'];
};

export type ConfiguratorConfigurationFilterResult = {
  __typename?: 'configuratorConfigurationFilterResult';
  aggregations?: Maybe<Scalars['String']>;
  hits?: Maybe<Scalars['String']>;
};

export type ConfiguratorConfigurationGalleryItem = {
  __typename?: 'configuratorConfigurationGalleryItem';
  type: Scalars['String'];
  url: Scalars['String'];
};

export type ConfiguratorConfigurationGalleryItemInput = {
  base64EncodedData: Scalars['String'];
  code: Scalars['String'];
};

export enum ConfiguratorConfigurationGalleryItemType {
  Detail = 'detail',
  Main = 'main'
}

export type ConfiguratorConfigurationHasRequiredSelectionResult = {
  __typename?: 'configuratorConfigurationHasRequiredSelectionResult';
  hasRequiredSelections: Scalars['Boolean'];
  missingModelNodes?: Maybe<Array<ConfiguratorMissingModelNodes>>;
};

export type ConfiguratorConfigurationHasSelectionResult = {
  __typename?: 'configuratorConfigurationHasSelectionResult';
  hasValidSelection: Scalars['Boolean'];
  modelNode?: Maybe<Scalars['String']>;
};

export type ConfiguratorConfigurationResult = {
  __typename?: 'configuratorConfigurationResult';
  body?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  details?: Maybe<Array<Maybe<ConfiguratorConfigurationDetailGroup>>>;
  finalPrice?: Maybe<Scalars['Float']>;
  originalPrice?: Maybe<Scalars['Float']>;
  overrides?: Maybe<Array<Maybe<ConfiguratorModelPathOverrideResult>>>;
  snapshotGallery?: Maybe<Array<Maybe<ConfiguratorConfigurationSnapshotResult>>>;
  snapshotImage?: Maybe<Scalars['String']>;
};

export type ConfiguratorConfigurationSnapshotResult = {
  __typename?: 'configuratorConfigurationSnapshotResult';
  imageUrl?: Maybe<Scalars['String']>;
  preset?: Maybe<Scalars['String']>;
};

export type ConfiguratorDetailPricingInfo = {
  __typename?: 'configuratorDetailPricingInfo';
  overrides?: Maybe<Array<Maybe<ConfiguratorDetailPricingInfoOverrideItem>>>;
  price?: Maybe<Scalars['Float']>;
};

export type ConfiguratorDetailPricingInfoOverrideItem = {
  __typename?: 'configuratorDetailPricingInfoOverrideItem';
  path?: Maybe<Scalars['String']>;
  previous_value?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ConfiguratorEcommerceDataParameter = {
  product_id: Scalars['String'];
};

export type ConfiguratorForgotPasswordInput = {
  email: Scalars['String'];
};

export type ConfiguratorForgotPasswordResponse = {
  __typename?: 'configuratorForgotPasswordResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type ConfiguratorJsonExport = {
  __typename?: 'configuratorJSONExport';
  model?: Maybe<Scalars['String']>;
  presets?: Maybe<Scalars['String']>;
};

export type ConfiguratorJsonImport = {
  __typename?: 'configuratorJSONImport';
  model_id?: Maybe<Scalars['String']>;
};

export type ConfiguratorLoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type ConfiguratorLogoutResponse = {
  __typename?: 'configuratorLogoutResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type ConfiguratorMakeDirResult = {
  __typename?: 'configuratorMakeDirResult';
  id?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type ConfiguratorMissingModelNodes = {
  __typename?: 'configuratorMissingModelNodes';
  modelNode: Scalars['String'];
  path: Scalars['String'];
};

export type ConfiguratorModel = {
  __typename?: 'configuratorModel';
  body?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
  parent_version?: Maybe<Scalars['String']>;
  pricing?: Maybe<ConfiguratorModelPricing>;
  version?: Maybe<Scalars['String']>;
};

export type ConfiguratorModelMediaResultItem = {
  __typename?: 'configuratorModelMediaResultItem';
  data?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
};

export type ConfiguratorModelPathOverride = {
  path: Scalars['String'];
  tag?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type ConfiguratorModelPathOverrideResult = {
  __typename?: 'configuratorModelPathOverrideResult';
  path: Scalars['String'];
  value: Scalars['String'];
};

export type ConfiguratorModelPricing = {
  __typename?: 'configuratorModelPricing';
  currency?: Maybe<Scalars['String']>;
  special_starting_price?: Maybe<Scalars['Float']>;
  starting_price?: Maybe<Scalars['Float']>;
};

export type ConfiguratorMyDesign = {
  __typename?: 'configuratorMyDesign';
  configuration_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  ecommerce_data?: Maybe<Scalars['String']>;
};

export type ConfiguratorNewPasswordWithCodeInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  token: Scalars['String'];
};

export type ConfiguratorNode = {
  __typename?: 'configuratorNode';
  children: Array<ConfiguratorNode>;
  code?: Maybe<Scalars['String']>;
  created_at: Scalars['configuratorDateTime'];
  descendants_have_nodes_of_presets?: Maybe<Array<Maybe<ConfiguratorNodePresetNode>>>;
  has_nodes_of_presets?: Maybe<Array<Maybe<ConfiguratorNodePresetNode>>>;
  id: Scalars['ID'];
  is_edited?: Maybe<Scalars['Boolean']>;
  node_attributes?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['configuratorDateTime'];
};

export type ConfiguratorNodePresetNode = {
  __typename?: 'configuratorNodePresetNode';
  preset_id: Scalars['Int'];
  preset_node_id: Scalars['Int'];
};

export enum ConfiguratorNodeType {
  Fieldset = 'fieldset',
  Option = 'option',
  Root = 'root',
  Select = 'select',
  String = 'string',
  Tab = 'tab',
  Upload = 'upload'
}

/** Allowed column names for the `orderBy` argument on the query `nodes`. */
export enum ConfiguratorNodesOrderByColumn {
  CreatedAt = 'CREATED_AT',
  InternalName = 'INTERNAL_NAME',
  ParentModelId = 'PARENT_MODEL_ID',
  PublicName = 'PUBLIC_NAME',
  Published = 'PUBLISHED',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `nodes`. */
export type ConfiguratorNodesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ConfiguratorNodesOrderByColumn;
  /** The direction that is used for ordering. */
  order: ConfiguratorSortOrder;
};

/** Allows ordering a list of records. */
export type ConfiguratorOrderByClause = {
  /** The column that is used for ordering. */
  field: Scalars['String'];
  /** The direction that is used for ordering. */
  order: ConfiguratorSortOrder;
};

/** Pagination information about the corresponding list of items. */
export type ConfiguratorPageInfo = {
  __typename?: 'configuratorPageInfo';
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>;
};

/** Pagination information about the corresponding list of items. */
export type ConfiguratorPaginatorInfo = {
  __typename?: 'configuratorPaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

export type ConfiguratorPreset = {
  __typename?: 'configuratorPreset';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  created_at: Scalars['configuratorDateTime'];
  id: Scalars['ID'];
  internal_name: Scalars['String'];
  model: ConfiguratorNode;
  nodes: Array<ConfiguratorPresetNode>;
  parent_model_id: Scalars['Int'];
  public_name: Scalars['String'];
  published: Scalars['Boolean'];
  published_code?: Maybe<Scalars['String']>;
  published_version?: Maybe<Scalars['String']>;
  updated_at: Scalars['configuratorDateTime'];
};


export type ConfiguratorPresetNodesArgs = {
  orderBy?: InputMaybe<Array<ConfiguratorNodesOrderByOrderByClause>>;
};

export type ConfiguratorPresetExportedItem = {
  __typename?: 'configuratorPresetExportedItem';
  error?: Maybe<Scalars['String']>;
  internal_name: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ConfiguratorPresetFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['configuratorDate']>;
  internal_name?: InputMaybe<Scalars['String']>;
  parent_model_id?: InputMaybe<Scalars['Int']>;
  public_name?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  published_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['configuratorDate']>;
};

export type ConfiguratorPresetNode = {
  __typename?: 'configuratorPresetNode';
  created_at: Scalars['configuratorDateTime'];
  definition: Scalars['String'];
  id: Scalars['ID'];
  parent_node_id: Scalars['Int'];
  preset: ConfiguratorPreset;
  preset_id: Scalars['Int'];
  removed: Scalars['Boolean'];
  updated_at: Scalars['configuratorDateTime'];
};

export type ConfiguratorPresetNodeFilterInput = {
  created_at?: InputMaybe<Scalars['configuratorDate']>;
  parent_node_id?: InputMaybe<Scalars['Int']>;
  preset_id?: InputMaybe<Scalars['Int']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['configuratorDate']>;
};

/** A paginated list of PresetNode items. */
export type ConfiguratorPresetNodePaginator = {
  __typename?: 'configuratorPresetNodePaginator';
  /** A list of PresetNode items. */
  data: Array<ConfiguratorPresetNode>;
  /** Pagination information about the list of items. */
  paginatorInfo: ConfiguratorPaginatorInfo;
};

/** Allowed column names for the `orderBy` argument on the query `presetNodes`. */
export enum ConfiguratorPresetNodesOrderByColumn {
  CreatedAt = 'CREATED_AT',
  ParentNode = 'PARENT_NODE',
  PresetId = 'PRESET_ID',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `presetNodes`. */
export type ConfiguratorPresetNodesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ConfiguratorPresetNodesOrderByColumn;
  /** The direction that is used for ordering. */
  order: ConfiguratorSortOrder;
};

/** A paginated list of Preset items. */
export type ConfiguratorPresetPaginator = {
  __typename?: 'configuratorPresetPaginator';
  /** A list of Preset items. */
  data: Array<ConfiguratorPreset>;
  /** Pagination information about the list of items. */
  paginatorInfo: ConfiguratorPaginatorInfo;
};

/** Allowed column names for the `orderBy` argument on the query `presets`. */
export enum ConfiguratorPresetsOrderByColumn {
  Active = 'ACTIVE',
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  InternalName = 'INTERNAL_NAME',
  ParentModelId = 'PARENT_MODEL_ID',
  PublicName = 'PUBLIC_NAME',
  Published = 'PUBLISHED',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `presets`. */
export type ConfiguratorPresetsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ConfiguratorPresetsOrderByColumn;
  /** The direction that is used for ordering. */
  order: ConfiguratorSortOrder;
};

export type ConfiguratorRefreshTokenInput = {
  refresh_token?: InputMaybe<Scalars['String']>;
};

export type ConfiguratorRegisterInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
};

export type ConfiguratorRenderStackResult = {
  __typename?: 'configuratorRenderStackResult';
  code?: Maybe<Scalars['String']>;
  stack?: Maybe<Scalars['String']>;
};

/** Semantic versioning options */
export enum ConfiguratorSemanticVersion {
  Major = 'major',
  Minor = 'minor',
  Patch = 'patch'
}

/** The available directions for ordering a list of records. */
export enum ConfiguratorSortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum ConfiguratorTrashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type ConfiguratorUploadModelMediaResult = {
  __typename?: 'configuratorUploadModelMediaResult';
  relativePath?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ConfiguratorUser = {
  __typename?: 'configuratorUser';
  created_at: Scalars['configuratorDateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['configuratorDateTime'];
};

/** A paginated list of User items. */
export type ConfiguratorUserPaginator = {
  __typename?: 'configuratorUserPaginator';
  /** A list of User items. */
  data: Array<ConfiguratorUser>;
  /** Pagination information about the list of items. */
  paginatorInfo: ConfiguratorPaginatorInfo;
};

export type PackagesAvailableProduct = {
  __typename?: 'packagesAvailableProduct';
  name: Scalars['String'];
  pricing?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  type: Scalars['String'];
};

/** Allows ordering a list of records. */
export type PackagesOrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

export enum PackagesOrderByDirection {
  /** Sort in ascending order. */
  Asc = 'ASC',
  /** Sort in descending order. */
  Desc = 'DESC'
}

export type PackagesPackage = {
  __typename?: 'packagesPackage';
  active: Scalars['Boolean'];
  ajat_id: Scalars['String'];
  base_price?: Maybe<Scalars['Float']>;
  created_at: Scalars['packagesDateTime'];
  definition: Scalars['String'];
  final_price: Scalars['Float'];
  gallery?: Maybe<Array<PackagesPackageGalleryItem>>;
  groups?: Maybe<Array<PackagesPackageGroup>>;
  id: Scalars['ID'];
  is_default: Scalars['Boolean'];
  is_favourite: Scalars['Boolean'];
  long_description?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  overlay_text?: Maybe<Scalars['String']>;
  overlay_text_bottom?: Maybe<Scalars['String']>;
  price_tooltip?: Maybe<Scalars['String']>;
  products: Array<PackagesPackageProduct>;
  public_name: Scalars['String'];
  short_description?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at: Scalars['packagesDateTime'];
  wp_slug?: Maybe<Scalars['String']>;
};


export type PackagesPackageGroupsArgs = {
  filter?: InputMaybe<PackagesPackageGroupFilterInput>;
};


export type PackagesPackageProductsArgs = {
  filter?: InputMaybe<PackagesPackageProductFilterInput>;
};

export type PackagesPackageFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  ajat_id?: InputMaybe<Scalars['String']>;
  base_price?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['packagesDate']>;
  final_price?: InputMaybe<Scalars['Float']>;
  internal_name?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  overlay_text?: InputMaybe<Scalars['String']>;
  overlay_text_bottom?: InputMaybe<Scalars['String']>;
  price_tooltip?: InputMaybe<Scalars['String']>;
  public_name?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PackagesPackageType>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
  wp_slug?: InputMaybe<Scalars['String']>;
};

export type PackagesPackageGalleryItem = {
  __typename?: 'packagesPackageGalleryItem';
  alt: Scalars['String'];
  created_at: Scalars['packagesDateTime'];
  id: Scalars['ID'];
  package_id: Scalars['Int'];
  path: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['packagesDateTime'];
  url?: Maybe<Scalars['String']>;
};

export type PackagesPackageGalleryItemFilterInput = {
  created_at?: InputMaybe<Scalars['packagesDate']>;
  package_id?: InputMaybe<Scalars['Int']>;
  path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
};

/** A paginated list of PackageGalleryItem items. */
export type PackagesPackageGalleryItemPaginator = {
  __typename?: 'packagesPackageGalleryItemPaginator';
  /** A list of PackageGalleryItem items. */
  data: Array<PackagesPackageGalleryItem>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

export type PackagesPackageGroup = {
  __typename?: 'packagesPackageGroup';
  active: Scalars['Boolean'];
  base_price?: Maybe<Scalars['Float']>;
  code: Scalars['String'];
  collection_id: Scalars['Int'];
  created_at: Scalars['packagesDateTime'];
  final_price: Scalars['Float'];
  gallery?: Maybe<Array<PackagesPackageGroupGalleryItem>>;
  id: Scalars['ID'];
  internal_name: Scalars['String'];
  is_default: Scalars['Boolean'];
  is_favourite: Scalars['Boolean'];
  is_free_design: Scalars['Boolean'];
  long_description?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  overlay_text?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<PackagesPackage>>;
  presets?: Maybe<Array<PackagesPackageGroupPreset>>;
  price_tooltip?: Maybe<Scalars['String']>;
  public_name: Scalars['String'];
  short_description?: Maybe<Scalars['String']>;
  updated_at: Scalars['packagesDateTime'];
  usps?: Maybe<Scalars['String']>;
  wp_slug?: Maybe<Scalars['String']>;
};


export type PackagesPackageGroupPackagesArgs = {
  filter?: InputMaybe<PackagesPackageFilterInput>;
  orderBy?: InputMaybe<Array<PackagesPackageGroupPackagesOrderByOrderByClause>>;
};

export type PackagesPackageGroupCollection = {
  __typename?: 'packagesPackageGroupCollection';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  created_at: Scalars['packagesDateTime'];
  gallery?: Maybe<Array<PackagesPackageGroupCollectionGalleryItem>>;
  groups?: Maybe<Array<PackagesPackageGroup>>;
  id: Scalars['ID'];
  internal_name: Scalars['String'];
  is_default: Scalars['Boolean'];
  is_favourite: Scalars['Boolean'];
  long_description?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  public_name: Scalars['String'];
  short_description?: Maybe<Scalars['String']>;
  updated_at: Scalars['packagesDateTime'];
  usps?: Maybe<Scalars['String']>;
};


export type PackagesPackageGroupCollectionGroupsArgs = {
  filter?: InputMaybe<PackagesPackageGroupFilterInput>;
  orderBy?: InputMaybe<Array<PackagesPackageGroupCollectionGroupsOrderByOrderByClause>>;
};

export type PackagesPackageGroupCollectionFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['packagesDate']>;
  internal_name?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  public_name?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
  usps?: InputMaybe<Scalars['String']>;
};

export type PackagesPackageGroupCollectionGalleryItem = {
  __typename?: 'packagesPackageGroupCollectionGalleryItem';
  alt: Scalars['String'];
  collection_id: Scalars['Int'];
  created_at: Scalars['packagesDateTime'];
  id: Scalars['ID'];
  path: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['packagesDateTime'];
  url?: Maybe<Scalars['String']>;
};

export type PackagesPackageGroupCollectionGalleryItemFilterInput = {
  collection_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['packagesDate']>;
  path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
};

/** A paginated list of PackageGroupCollectionGalleryItem items. */
export type PackagesPackageGroupCollectionGalleryItemPaginator = {
  __typename?: 'packagesPackageGroupCollectionGalleryItemPaginator';
  /** A list of PackageGroupCollectionGalleryItem items. */
  data: Array<PackagesPackageGroupCollectionGalleryItem>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

/** Allowed column names for the `orderBy` argument on field `groups` on type `PackageGroupCollection`. */
export enum PackagesPackageGroupCollectionGroupsOrderByColumn {
  Order = 'ORDER'
}

/** Order by clause for the `orderBy` argument on the query `groups`. */
export type PackagesPackageGroupCollectionGroupsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesPackageGroupCollectionGroupsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** A paginated list of PackageGroupCollection items. */
export type PackagesPackageGroupCollectionPaginator = {
  __typename?: 'packagesPackageGroupCollectionPaginator';
  /** A list of PackageGroupCollection items. */
  data: Array<PackagesPackageGroupCollection>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

export type PackagesPackageGroupFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  base_price?: InputMaybe<Scalars['Float']>;
  code?: InputMaybe<Scalars['String']>;
  collection_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['packagesDate']>;
  final_price?: InputMaybe<Scalars['Float']>;
  internal_name?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  is_free_design?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  overlay_text?: InputMaybe<Scalars['String']>;
  price_tooltip?: InputMaybe<Scalars['String']>;
  public_name?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
  usps?: InputMaybe<Scalars['String']>;
  wp_slug?: InputMaybe<Scalars['String']>;
};

export type PackagesPackageGroupGalleryItem = {
  __typename?: 'packagesPackageGroupGalleryItem';
  alt: Scalars['String'];
  created_at: Scalars['packagesDateTime'];
  group_id: Scalars['Int'];
  id: Scalars['ID'];
  path: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['packagesDateTime'];
  url?: Maybe<Scalars['String']>;
};

export type PackagesPackageGroupGalleryItemFilterInput = {
  created_at?: InputMaybe<Scalars['packagesDate']>;
  group_id?: InputMaybe<Scalars['Int']>;
  path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
};

/** A paginated list of PackageGroupGalleryItem items. */
export type PackagesPackageGroupGalleryItemPaginator = {
  __typename?: 'packagesPackageGroupGalleryItemPaginator';
  /** A list of PackageGroupGalleryItem items. */
  data: Array<PackagesPackageGroupGalleryItem>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

export type PackagesPackageGroupPackage = {
  __typename?: 'packagesPackageGroupPackage';
  group_id: Scalars['Int'];
  id: Scalars['ID'];
  package_id: Scalars['Int'];
};

/** Allowed column names for the `orderBy` argument on field `packages` on type `PackageGroup`. */
export enum PackagesPackageGroupPackagesOrderByColumn {
  Order = 'ORDER'
}

/** Order by clause for the `orderBy` argument on the query `packages`. */
export type PackagesPackageGroupPackagesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesPackageGroupPackagesOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** A paginated list of PackageGroup items. */
export type PackagesPackageGroupPaginator = {
  __typename?: 'packagesPackageGroupPaginator';
  /** A list of PackageGroup items. */
  data: Array<PackagesPackageGroup>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

export type PackagesPackageGroupPreset = {
  __typename?: 'packagesPackageGroupPreset';
  code: Scalars['String'];
  id: Scalars['ID'];
  sku: Scalars['String'];
};

/** A paginated list of Package items. */
export type PackagesPackagePaginator = {
  __typename?: 'packagesPackagePaginator';
  /** A list of Package items. */
  data: Array<PackagesPackage>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

export type PackagesPackageProduct = {
  __typename?: 'packagesPackageProduct';
  active: Scalars['Boolean'];
  comparison_label?: Maybe<Scalars['String']>;
  created_at: Scalars['packagesDateTime'];
  definition: Scalars['String'];
  id: Scalars['ID'];
  included_options?: Maybe<Scalars['String']>;
  is_included: Scalars['Boolean'];
  is_main: Scalars['Boolean'];
  is_optional: Scalars['Boolean'];
  package_id: Scalars['Int'];
  preset_code?: Maybe<Scalars['String']>;
  pricing?: Maybe<Scalars['String']>;
  root_model?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  type: PackagesPackageProductType;
  updated_at: Scalars['packagesDateTime'];
};

export type PackagesPackageProductFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  comparison_label?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['packagesDate']>;
  package_id?: InputMaybe<Scalars['Int']>;
  preset_code?: InputMaybe<Scalars['String']>;
  root_model?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
};

export type PackagesPackageProductInput = {
  __typename?: 'packagesPackageProductInput';
  active: Scalars['Boolean'];
  definition: Scalars['String'];
  package_id: Scalars['Int'];
  sku: Scalars['String'];
};

/** A paginated list of PackageProduct items. */
export type PackagesPackageProductPaginator = {
  __typename?: 'packagesPackageProductPaginator';
  /** A list of PackageProduct items. */
  data: Array<PackagesPackageProduct>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

export enum PackagesPackageProductType {
  Configurable = 'configurable',
  Configuration = 'configuration',
  Simple = 'simple'
}

export enum PackagesPackageType {
  Megadeal = 'megadeal',
  Package = 'package'
}

/** Information about pagination using a Relay style cursor connection. */
export type PackagesPageInfo = {
  __typename?: 'packagesPageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PackagesPaginatorInfo = {
  __typename?: 'packagesPaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

/** Allowed column names for the `orderBy` argument on field `packageGalleryItems` on type `Query`. */
export enum PackagesQueryPackageGalleryItemsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  PackageId = 'PACKAGE_ID',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `packageGalleryItems`. */
export type PackagesQueryPackageGalleryItemsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackageGalleryItemsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** Allowed column names for the `orderBy` argument on field `packageGroupCollectionGalleryItems` on type `Query`. */
export enum PackagesQueryPackageGroupCollectionGalleryItemsOrderByColumn {
  CollectionId = 'COLLECTION_ID',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `packageGroupCollectionGalleryItems`. */
export type PackagesQueryPackageGroupCollectionGalleryItemsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackageGroupCollectionGalleryItemsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** Allowed column names for the `orderBy` argument on field `packageGroupCollections` on type `Query`. */
export enum PackagesQueryPackageGroupCollectionsOrderByColumn {
  Active = 'ACTIVE',
  CreatedAt = 'CREATED_AT',
  InternalName = 'INTERNAL_NAME',
  IsDefault = 'IS_DEFAULT',
  IsFavourite = 'IS_FAVOURITE',
  LongDescription = 'LONG_DESCRIPTION',
  Order = 'ORDER',
  PublicName = 'PUBLIC_NAME',
  ShortDescription = 'SHORT_DESCRIPTION',
  UpdatedAt = 'UPDATED_AT',
  Usps = 'USPS'
}

/** Order by clause for the `orderBy` argument on the query `packageGroupCollections`. */
export type PackagesQueryPackageGroupCollectionsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackageGroupCollectionsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** Allowed column names for the `orderBy` argument on field `packageGroupGalleryItems` on type `Query`. */
export enum PackagesQueryPackageGroupGalleryItemsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  GroupId = 'GROUP_ID',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `packageGroupGalleryItems`. */
export type PackagesQueryPackageGroupGalleryItemsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackageGroupGalleryItemsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** Allowed column names for the `orderBy` argument on field `packageGroups` on type `Query`. */
export enum PackagesQueryPackageGroupsOrderByColumn {
  Active = 'ACTIVE',
  BasePrice = 'BASE_PRICE',
  CreatedAt = 'CREATED_AT',
  FinalPrice = 'FINAL_PRICE',
  InternalName = 'INTERNAL_NAME',
  IsDefault = 'IS_DEFAULT',
  IsFavourite = 'IS_FAVOURITE',
  IsFreeDesign = 'IS_FREE_DESIGN',
  LongDescription = 'LONG_DESCRIPTION',
  Order = 'ORDER',
  OverlayText = 'OVERLAY_TEXT',
  PriceTooltip = 'PRICE_TOOLTIP',
  PublicName = 'PUBLIC_NAME',
  ShortDescription = 'SHORT_DESCRIPTION',
  UpdatedAt = 'UPDATED_AT',
  Usps = 'USPS',
  WpSlug = 'WP_SLUG'
}

/** Order by clause for the `orderBy` argument on the query `packageGroups`. */
export type PackagesQueryPackageGroupsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackageGroupsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** Allowed column names for the `orderBy` argument on field `packageProducts` on type `Query`. */
export enum PackagesQueryPackageProductsOrderByColumn {
  Active = 'ACTIVE',
  CreatedAt = 'CREATED_AT',
  PackageId = 'PACKAGE_ID',
  PresetCode = 'PRESET_CODE',
  RootModel = 'ROOT_MODEL',
  Sku = 'SKU',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `packageProducts`. */
export type PackagesQueryPackageProductsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackageProductsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** Allowed column names for the `orderBy` argument on field `packages` on type `Query`. */
export enum PackagesQueryPackagesOrderByColumn {
  Active = 'ACTIVE',
  AjatId = 'AJAT_ID',
  BasePrice = 'BASE_PRICE',
  CreatedAt = 'CREATED_AT',
  FinalPrice = 'FINAL_PRICE',
  IsDefault = 'IS_DEFAULT',
  IsFavourite = 'IS_FAVOURITE',
  LongDescription = 'LONG_DESCRIPTION',
  Order = 'ORDER',
  OverlayText = 'OVERLAY_TEXT',
  OverlayTextBottom = 'OVERLAY_TEXT_BOTTOM',
  PriceTooltip = 'PRICE_TOOLTIP',
  PublicName = 'PUBLIC_NAME',
  ShortDescription = 'SHORT_DESCRIPTION',
  UpdatedAt = 'UPDATED_AT',
  WpSlug = 'WP_SLUG'
}

/** Order by clause for the `orderBy` argument on the query `packages`. */
export type PackagesQueryPackagesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackagesOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

export type PackagesSavedPackage = {
  __typename?: 'packagesSavedPackage';
  code: Scalars['String'];
  created_at?: Maybe<Scalars['packagesDateTime']>;
  selection: PackagesSavedPackageSelection;
};

export type PackagesSavedPackageSelection = {
  __typename?: 'packagesSavedPackageSelection';
  allow_package?: Maybe<Scalars['String']>;
  allow_package_group?: Maybe<Scalars['String']>;
  base_price?: Maybe<Scalars['Float']>;
  collection: Scalars['String'];
  configurations?: Maybe<Array<Maybe<PackagesSavedPackageSelectionConfiguration>>>;
  final_price: Scalars['Float'];
  group: Scalars['String'];
  package: Scalars['String'];
  products: Array<Maybe<PackagesSelectionProduct>>;
  title?: Maybe<Scalars['String']>;
};

export type PackagesSavedPackageSelectionConfiguration = {
  __typename?: 'packagesSavedPackageSelectionConfiguration';
  configuration_code: Scalars['String'];
  sku: Scalars['String'];
};

export type PackagesSelectionProduct = {
  __typename?: 'packagesSelectionProduct';
  comment?: Maybe<Scalars['String']>;
  configuration_code: Scalars['String'];
  metadata?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
};

/** Information about pagination using a simple paginator. */
export type PackagesSimplePaginatorInfo = {
  __typename?: 'packagesSimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** The available directions for ordering a list of records. */
export enum PackagesSortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum PackagesTrashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export enum PersonalizationAvailableMentionsTypes {
  All = 'all',
  Constant = 'constant',
  Retailer = 'retailer'
}

/** Allows ordering a list of records. */
export type PersonalizationOrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: PersonalizationSortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum PersonalizationOrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum PersonalizationOrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a Relay style cursor connection. */
export type PersonalizationPageInfo = {
  __typename?: 'personalizationPageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PersonalizationPaginatorInfo = {
  __typename?: 'personalizationPaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

/** Allowed column names for Query.rules.orderBy. */
export enum PersonalizationQueryRulesOrderByColumn {
  Active = 'ACTIVE',
  Break = 'BREAK',
  CreatedAt = 'CREATED_AT',
  Label = 'LABEL',
  Priority = 'PRIORITY',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.rules.orderBy. */
export type PersonalizationQueryRulesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PersonalizationQueryRulesOrderByColumn;
  /** The direction that is used for ordering. */
  order: PersonalizationSortOrder;
};

export type PersonalizationRule = {
  __typename?: 'personalizationRule';
  actions: Scalars['String'];
  active: Scalars['Boolean'];
  break?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['personalizationDateTime'];
  id: Scalars['ID'];
  label: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  rules: Scalars['String'];
  updated_at: Scalars['personalizationDateTime'];
};

export type PersonalizationRuleFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  break?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['personalizationDate']>;
  label?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['personalizationDate']>;
};

/** A paginated list of Rule items. */
export type PersonalizationRulePaginator = {
  __typename?: 'personalizationRulePaginator';
  /** A list of Rule items. */
  data: Array<PersonalizationRule>;
  /** Pagination information about the list of items. */
  paginatorInfo: PersonalizationPaginatorInfo;
};

export type PersonalizationRulePathUpdateInput = {
  id: Scalars['ID'];
  path: Scalars['String'];
  value: Scalars['String'];
};

export type PersonalizationSelectItem = {
  __typename?: 'personalizationSelectItem';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Information about pagination using a simple paginator. */
export type PersonalizationSimplePaginatorInfo = {
  __typename?: 'personalizationSimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum PersonalizationSortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum PersonalizationTrashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type RetailersCountry = {
  __typename?: 'retailersCountry';
  full_name_english?: Maybe<Scalars['String']>;
  full_name_locale?: Maybe<Scalars['String']>;
  three_letter_abbreviation?: Maybe<Scalars['String']>;
  two_letter_abbreviation?: Maybe<Scalars['String']>;
};

export type RetailersEducation = {
  __typename?: 'retailersEducation';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  created_at: Scalars['retailersDateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  retailers: Array<Maybe<RetailersRetailer>>;
  updated_at: Scalars['retailersDateTime'];
};

export type RetailersEducationFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['retailersDate']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['retailersDate']>;
};

/** A paginated list of Education items. */
export type RetailersEducationPaginator = {
  __typename?: 'retailersEducationPaginator';
  /** A list of Education items. */
  data: Array<RetailersEducation>;
  /** Pagination information about the list of items. */
  paginatorInfo: RetailersPaginatorInfo;
};

export type RetailersExtensionAttribute = {
  __typename?: 'retailersExtensionAttribute';
  attribute_backend_type: Scalars['String'];
  attribute_code: Scalars['String'];
  attribute_frontend_type: Scalars['String'];
  attribute_label: Scalars['String'];
  created_at?: Maybe<Scalars['retailersDateTime']>;
  entity_type: Scalars['String'];
  id: Scalars['ID'];
  updated_at?: Maybe<Scalars['retailersDateTime']>;
};

export type RetailersExtensionAttributeFilterInput = {
  attribute_code?: InputMaybe<Scalars['String']>;
  attribute_label?: InputMaybe<Scalars['String']>;
  entity_type?: InputMaybe<Scalars['String']>;
};

/** A paginated list of ExtensionAttribute items. */
export type RetailersExtensionAttributePaginator = {
  __typename?: 'retailersExtensionAttributePaginator';
  /** A list of ExtensionAttribute items. */
  data: Array<RetailersExtensionAttribute>;
  /** Pagination information about the list of items. */
  paginatorInfo: RetailersPaginatorInfo;
};

/** Allows ordering a list of records. */
export type RetailersOrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum RetailersOrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum RetailersOrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a Relay style cursor connection. */
export type RetailersPageInfo = {
  __typename?: 'retailersPageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type RetailersPaginatorInfo = {
  __typename?: 'retailersPaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

/** Allowed column names for Query.educations.orderBy. */
export enum RetailersQueryEducationsOrderByColumn {
  Active = 'ACTIVE',
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Priority = 'PRIORITY',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.educations.orderBy. */
export type RetailersQueryEducationsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersQueryEducationsOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Allowed column names for Query.extensionAttributes.orderBy. */
export enum RetailersQueryExtensionAttributesOrderByColumn {
  AttributeCode = 'ATTRIBUTE_CODE',
  AttributeLabel = 'ATTRIBUTE_LABEL',
  CreatedAt = 'CREATED_AT',
  EntityType = 'ENTITY_TYPE',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.extensionAttributes.orderBy. */
export type RetailersQueryExtensionAttributesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersQueryExtensionAttributesOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Allowed column names for Query.retailerClassByCodes.retailer. */
export enum RetailersQueryRetailerClassByCodesRetailerColumn {
  Code = 'CODE'
}

/** Dynamic WHERE conditions for the `retailer` argument on the query `retailerClassByCodes`. */
export type RetailersQueryRetailerClassByCodesRetailerWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<RetailersQueryRetailerClassByCodesRetailerWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<RetailersQueryRetailerClassByCodesRetailerWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<RetailersQueryRetailerClassByCodesRetailerWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<RetailersQueryRetailerClassByCodesRetailerColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['retailersMixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `retailer` argument on the query `retailerClassByCodes`. */
export type RetailersQueryRetailerClassByCodesRetailerWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<RetailersQueryRetailerClassByCodesRetailerWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.retailerClassRepresentatives.orderBy. */
export enum RetailersQueryRetailerClassRepresentativesOrderByColumn {
  CreatedAt = 'CREATED_AT',
  DiscountUsed = 'DISCOUNT_USED',
  Email = 'EMAIL',
  Name = 'NAME',
  Ssn = 'SSN',
  Telephone = 'TELEPHONE',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.retailerClassRepresentatives.orderBy. */
export type RetailersQueryRetailerClassRepresentativesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersQueryRetailerClassRepresentativesOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Allowed column names for Query.retailerClassRepresentatives.where. */
export enum RetailersQueryRetailerClassRepresentativesWhereColumn {
  DiscountUsed = 'DISCOUNT_USED',
  Email = 'EMAIL',
  Name = 'NAME',
  Ssn = 'SSN',
  Telephone = 'TELEPHONE'
}

/** Dynamic WHERE conditions for the `where` argument on the query `retailerClassRepresentatives`. */
export type RetailersQueryRetailerClassRepresentativesWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<RetailersQueryRetailerClassRepresentativesWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<RetailersQueryRetailerClassRepresentativesWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<RetailersQueryRetailerClassRepresentativesWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<RetailersQueryRetailerClassRepresentativesWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['retailersMixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `retailerClassRepresentatives`. */
export type RetailersQueryRetailerClassRepresentativesWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<RetailersQueryRetailerClassRepresentativesWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.retailerClassRetailerTags.orderBy. */
export enum RetailersQueryRetailerClassRetailerTagsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Tag = 'TAG',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.retailerClassRetailerTags.orderBy. */
export type RetailersQueryRetailerClassRetailerTagsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersQueryRetailerClassRetailerTagsOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Allowed column names for Query.retailerClasses.orderBy. */
export enum RetailersQueryRetailerClassesOrderByColumn {
  Active = 'ACTIVE',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Priority = 'PRIORITY',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.retailerClasses.orderBy. */
export type RetailersQueryRetailerClassesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersQueryRetailerClassesOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Allowed column names for Query.retailerClasses.where. */
export enum RetailersQueryRetailerClassesWhereColumn {
  Code = 'CODE',
  Name = 'NAME'
}

/** Dynamic WHERE conditions for the `where` argument on the query `retailerClasses`. */
export type RetailersQueryRetailerClassesWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<RetailersQueryRetailerClassesWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<RetailersQueryRetailerClassesWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<RetailersQueryRetailerClassesWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<RetailersQueryRetailerClassesWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['retailersMixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `retailerClasses`. */
export type RetailersQueryRetailerClassesWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<RetailersQueryRetailerClassesWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.retailerEducations.orderBy. */
export enum RetailersQueryRetailerEducationsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Tag = 'TAG',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.retailerEducations.orderBy. */
export type RetailersQueryRetailerEducationsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersQueryRetailerEducationsOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Allowed column names for Query.retailerPeriods.orderBy. */
export enum RetailersQueryRetailerPeriodsOrderByColumn {
  Active = 'ACTIVE',
  CreatedAt = 'CREATED_AT',
  FromDate = 'FROM_DATE',
  GracePaymentDate = 'GRACE_PAYMENT_DATE',
  Name = 'NAME',
  ToDate = 'TO_DATE',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.retailerPeriods.orderBy. */
export type RetailersQueryRetailerPeriodsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersQueryRetailerPeriodsOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Allowed column names for Query.retailerRetailerTags.orderBy. */
export enum RetailersQueryRetailerRetailerTagsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Tag = 'TAG',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.retailerRetailerTags.orderBy. */
export type RetailersQueryRetailerRetailerTagsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersQueryRetailerRetailerTagsOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Allowed column names for Query.retailerSalesResponsibles.orderBy. */
export enum RetailersQueryRetailerSalesResponsiblesOrderByColumn {
  Name = 'NAME'
}

/** Order by clause for Query.retailerSalesResponsibles.orderBy. */
export type RetailersQueryRetailerSalesResponsiblesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersQueryRetailerSalesResponsiblesOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Allowed column names for Query.retailerSalesResponsibles.where. */
export enum RetailersQueryRetailerSalesResponsiblesWhereColumn {
  Active = 'ACTIVE',
  Name = 'NAME'
}

/** Dynamic WHERE conditions for the `where` argument on the query `retailerSalesResponsibles`. */
export type RetailersQueryRetailerSalesResponsiblesWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<RetailersQueryRetailerSalesResponsiblesWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<RetailersQueryRetailerSalesResponsiblesWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<RetailersQueryRetailerSalesResponsiblesWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<RetailersQueryRetailerSalesResponsiblesWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['retailersMixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `retailerSalesResponsibles`. */
export type RetailersQueryRetailerSalesResponsiblesWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<RetailersQueryRetailerSalesResponsiblesWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.retailerSalesTeams.orderBy. */
export enum RetailersQueryRetailerSalesTeamsOrderByColumn {
  Name = 'NAME'
}

/** Order by clause for Query.retailerSalesTeams.orderBy. */
export type RetailersQueryRetailerSalesTeamsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersQueryRetailerSalesTeamsOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Allowed column names for Query.retailerSalesTeams.where. */
export enum RetailersQueryRetailerSalesTeamsWhereColumn {
  Active = 'ACTIVE',
  Name = 'NAME'
}

/** Dynamic WHERE conditions for the `where` argument on the query `retailerSalesTeams`. */
export type RetailersQueryRetailerSalesTeamsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<RetailersQueryRetailerSalesTeamsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<RetailersQueryRetailerSalesTeamsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<RetailersQueryRetailerSalesTeamsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<RetailersQueryRetailerSalesTeamsWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['retailersMixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `retailerSalesTeams`. */
export type RetailersQueryRetailerSalesTeamsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<RetailersQueryRetailerSalesTeamsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.retailerTags.orderBy. */
export enum RetailersQueryRetailerTagsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Tag = 'TAG',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.retailerTags.orderBy. */
export type RetailersQueryRetailerTagsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersQueryRetailerTagsOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Allowed column names for Query.retailerYears.orderBy. */
export enum RetailersQueryRetailerYearsOrderByColumn {
  Active = 'ACTIVE',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.retailerYears.orderBy. */
export type RetailersQueryRetailerYearsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersQueryRetailerYearsOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Allowed column names for Query.retailers.orderBy. */
export enum RetailersQueryRetailersOrderByColumn {
  Active = 'ACTIVE',
  Code = 'CODE',
  Country = 'COUNTRY',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Priority = 'PRIORITY',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.retailers.orderBy. */
export type RetailersQueryRetailersOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersQueryRetailersOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

/** Allowed column names for Query.retailers.where. */
export enum RetailersQueryRetailersWhereColumn {
  Code = 'CODE',
  Name = 'NAME'
}

/** Dynamic WHERE conditions for the `where` argument on the query `retailers`. */
export type RetailersQueryRetailersWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<RetailersQueryRetailersWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<RetailersQueryRetailersWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<RetailersQueryRetailersWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<RetailersQueryRetailersWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['retailersMixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `retailers`. */
export type RetailersQueryRetailersWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<RetailersQueryRetailersWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

export type RetailersRetailer = {
  __typename?: 'retailersRetailer';
  active: Scalars['Boolean'];
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  classes: Array<RetailersRetailerClass>;
  code: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['retailersDateTime'];
  educations: Array<RetailersEducation>;
  embroidery_1?: Maybe<Scalars['String']>;
  embroidery_2?: Maybe<Scalars['String']>;
  embroidery_3?: Maybe<Scalars['String']>;
  extension_attributes?: Maybe<Scalars['retailersJSON']>;
  hero_banner?: Maybe<Scalars['String']>;
  hero_banner_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internal_note?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  logo_2?: Maybe<Scalars['String']>;
  logo_2_url?: Maybe<Scalars['String']>;
  logo_3?: Maybe<Scalars['String']>;
  logo_3_url?: Maybe<Scalars['String']>;
  logo_4?: Maybe<Scalars['String']>;
  logo_4_url?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  marketing_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  periods: Array<RetailersRetailerPeriod>;
  personalization_logo?: Maybe<Scalars['String']>;
  personalization_logo_url?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  priority: Scalars['Int'];
  representative_email?: Maybe<Scalars['String']>;
  representative_name?: Maybe<Scalars['String']>;
  representative_ssn?: Maybe<Scalars['String']>;
  representative_telephone?: Maybe<Scalars['String']>;
  representatives: Array<RetailersRetailerClassRepresentative>;
  sales_responsible?: Maybe<Scalars['Int']>;
  sales_team?: Maybe<Scalars['Int']>;
  students_amount?: Maybe<Scalars['Int']>;
  tags: Array<RetailersRetailerTag>;
  updated_at: Scalars['retailersDateTime'];
};


export type RetailersRetailerClassesArgs = {
  filter?: InputMaybe<RetailersRetailerClassFilterInput>;
};


export type RetailersRetailerEducationsArgs = {
  filter?: InputMaybe<RetailersRetailerEducationFilterInput>;
};


export type RetailersRetailerPeriodsArgs = {
  filter?: InputMaybe<RetailersRetailerPeriodFilterInput>;
  orderBy?: InputMaybe<Array<RetailersRetailerPeriodsOrderByOrderByClause>>;
};

export type RetailersRetailerClass = {
  __typename?: 'retailersRetailerClass';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  created_at: Scalars['retailersDateTime'];
  embroidery_1?: Maybe<Scalars['String']>;
  embroidery_2?: Maybe<Scalars['String']>;
  embroidery_3?: Maybe<Scalars['String']>;
  extension_attributes?: Maybe<Scalars['retailersJSON']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  representatives: Array<RetailersRetailerClassRepresentative>;
  retailer?: Maybe<RetailersRetailer>;
  retailer_id: Scalars['Int'];
  retailer_year_id: Scalars['Int'];
  students_amount?: Maybe<Scalars['Int']>;
  tags: Array<RetailersRetailerTag>;
  updated_at: Scalars['retailersDateTime'];
  year?: Maybe<RetailersRetailerYear>;
};

export type RetailersRetailerClassFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['retailersDate']>;
  name?: InputMaybe<Scalars['String']>;
  retailer_id?: InputMaybe<Scalars['Int']>;
  retailer_year_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['retailersDate']>;
};

/** A paginated list of RetailerClass items. */
export type RetailersRetailerClassPaginator = {
  __typename?: 'retailersRetailerClassPaginator';
  /** A list of RetailerClass items. */
  data: Array<RetailersRetailerClass>;
  /** Pagination information about the list of items. */
  paginatorInfo: RetailersPaginatorInfo;
};

export type RetailersRetailerClassRepresentative = {
  __typename?: 'retailersRetailerClassRepresentative';
  classes: Array<Maybe<RetailersRetailerClass>>;
  created_at: Scalars['retailersDateTime'];
  discount_amount?: Maybe<Scalars['Float']>;
  discount_percentage?: Maybe<Scalars['Float']>;
  discount_used?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  retailers: Array<Maybe<RetailersRetailer>>;
  ssn?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  updated_at: Scalars['retailersDateTime'];
};

export type RetailersRetailerClassRepresentativeFilterInput = {
  created_at?: InputMaybe<Scalars['retailersDate']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['retailersDate']>;
};

/** A paginated list of RetailerClassRepresentative items. */
export type RetailersRetailerClassRepresentativePaginator = {
  __typename?: 'retailersRetailerClassRepresentativePaginator';
  /** A list of RetailerClassRepresentative items. */
  data: Array<RetailersRetailerClassRepresentative>;
  /** Pagination information about the list of items. */
  paginatorInfo: RetailersPaginatorInfo;
};

export type RetailersRetailerClassRetailerClassRepresentative = {
  __typename?: 'retailersRetailerClassRetailerClassRepresentative';
  id: Scalars['ID'];
  representative_id: Scalars['Int'];
  retailer_class_id: Scalars['Int'];
};

export type RetailersRetailerClassRetailerTag = {
  __typename?: 'retailersRetailerClassRetailerTag';
  created_at: Scalars['retailersDateTime'];
  retailer_class_id: Scalars['Int'];
  retailer_tag_id: Scalars['Int'];
  updated_at: Scalars['retailersDateTime'];
};

export type RetailersRetailerClassRetailerTagFilterInput = {
  created_at?: InputMaybe<Scalars['retailersDate']>;
  retailer_class_id?: InputMaybe<Scalars['Int']>;
  retailer_tag_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['retailersDate']>;
};

/** A paginated list of RetailerClassRetailerTag items. */
export type RetailersRetailerClassRetailerTagPaginator = {
  __typename?: 'retailersRetailerClassRetailerTagPaginator';
  /** A list of RetailerClassRetailerTag items. */
  data: Array<RetailersRetailerClassRetailerTag>;
  /** Pagination information about the list of items. */
  paginatorInfo: RetailersPaginatorInfo;
};

export type RetailersRetailerEducation = {
  __typename?: 'retailersRetailerEducation';
  created_at: Scalars['retailersDateTime'];
  education_id: Scalars['Int'];
  retailer_id: Scalars['Int'];
  updated_at: Scalars['retailersDateTime'];
};

export type RetailersRetailerEducationFilterInput = {
  created_at?: InputMaybe<Scalars['retailersDate']>;
  retailer_id?: InputMaybe<Scalars['Int']>;
  retailer_tag_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['retailersDate']>;
};

/** A paginated list of RetailerEducation items. */
export type RetailersRetailerEducationPaginator = {
  __typename?: 'retailersRetailerEducationPaginator';
  /** A list of RetailerEducation items. */
  data: Array<RetailersRetailerEducation>;
  /** Pagination information about the list of items. */
  paginatorInfo: RetailersPaginatorInfo;
};

export type RetailersRetailerFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['retailersDate']>;
  marketing_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['retailersDate']>;
};

/** A paginated list of Retailer items. */
export type RetailersRetailerPaginator = {
  __typename?: 'retailersRetailerPaginator';
  /** A list of Retailer items. */
  data: Array<RetailersRetailer>;
  /** Pagination information about the list of items. */
  paginatorInfo: RetailersPaginatorInfo;
};

export type RetailersRetailerPeriod = {
  __typename?: 'retailersRetailerPeriod';
  active: Scalars['Boolean'];
  created_at: Scalars['retailersDateTime'];
  from_date: Scalars['retailersDate'];
  grace_payment_date?: Maybe<Scalars['retailersDate']>;
  id: Scalars['ID'];
  retailer?: Maybe<RetailersRetailer>;
  to_date: Scalars['retailersDate'];
  updated_at: Scalars['retailersDateTime'];
};

export type RetailersRetailerPeriodFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['retailersDate']>;
  from_date?: InputMaybe<Scalars['retailersDate']>;
  grace_payment_date?: InputMaybe<Scalars['retailersDate']>;
  name?: InputMaybe<Scalars['String']>;
  retailer_id?: InputMaybe<Scalars['Int']>;
  to_date?: InputMaybe<Scalars['retailersDate']>;
  updated_at?: InputMaybe<Scalars['retailersDate']>;
};

/** A paginated list of RetailerPeriod items. */
export type RetailersRetailerPeriodPaginator = {
  __typename?: 'retailersRetailerPeriodPaginator';
  /** A list of RetailerPeriod items. */
  data: Array<RetailersRetailerPeriod>;
  /** Pagination information about the list of items. */
  paginatorInfo: RetailersPaginatorInfo;
};

/** Allowed column names for Retailer.periods.orderBy. */
export enum RetailersRetailerPeriodsOrderByColumn {
  Active = 'ACTIVE',
  CreatedAt = 'CREATED_AT',
  FromDate = 'FROM_DATE',
  GracePaymentDate = 'GRACE_PAYMENT_DATE',
  Name = 'NAME',
  ToDate = 'TO_DATE',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Retailer.periods.orderBy. */
export type RetailersRetailerPeriodsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: RetailersRetailerPeriodsOrderByColumn;
  /** The direction that is used for ordering. */
  order: RetailersSortOrder;
};

export type RetailersRetailerRetailerClassRepresentative = {
  __typename?: 'retailersRetailerRetailerClassRepresentative';
  id: Scalars['ID'];
  representative_id: Scalars['Int'];
  retailer_id: Scalars['Int'];
};

export type RetailersRetailerRetailerTag = {
  __typename?: 'retailersRetailerRetailerTag';
  created_at: Scalars['retailersDateTime'];
  retailer_id: Scalars['Int'];
  retailer_tag_id: Scalars['Int'];
  updated_at: Scalars['retailersDateTime'];
};

export type RetailersRetailerRetailerTagFilterInput = {
  created_at?: InputMaybe<Scalars['retailersDate']>;
  retailer_id?: InputMaybe<Scalars['Int']>;
  retailer_tag_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['retailersDate']>;
};

/** A paginated list of RetailerRetailerTag items. */
export type RetailersRetailerRetailerTagPaginator = {
  __typename?: 'retailersRetailerRetailerTagPaginator';
  /** A list of RetailerRetailerTag items. */
  data: Array<RetailersRetailerRetailerTag>;
  /** Pagination information about the list of items. */
  paginatorInfo: RetailersPaginatorInfo;
};

export type RetailersRetailerSalesResponsible = {
  __typename?: 'retailersRetailerSalesResponsible';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RetailersRetailerSalesResponsibleFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A paginated list of RetailerSalesResponsible items. */
export type RetailersRetailerSalesResponsiblePaginator = {
  __typename?: 'retailersRetailerSalesResponsiblePaginator';
  /** A list of RetailerSalesResponsible items. */
  data: Array<RetailersRetailerSalesResponsible>;
  /** Pagination information about the list of items. */
  paginatorInfo: RetailersPaginatorInfo;
};

export type RetailersRetailerSalesTeam = {
  __typename?: 'retailersRetailerSalesTeam';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RetailersRetailerSalesTeamFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A paginated list of RetailerSalesTeam items. */
export type RetailersRetailerSalesTeamPaginator = {
  __typename?: 'retailersRetailerSalesTeamPaginator';
  /** A list of RetailerSalesTeam items. */
  data: Array<RetailersRetailerSalesTeam>;
  /** Pagination information about the list of items. */
  paginatorInfo: RetailersPaginatorInfo;
};

export type RetailersRetailerTag = {
  __typename?: 'retailersRetailerTag';
  created_at: Scalars['retailersDateTime'];
  id: Scalars['ID'];
  retailers: Array<Maybe<RetailersRetailer>>;
  tag: Scalars['String'];
  updated_at: Scalars['retailersDateTime'];
};

export type RetailersRetailerTagFilterInput = {
  created_at?: InputMaybe<Scalars['retailersDate']>;
  tag?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['retailersDate']>;
};

/** A paginated list of RetailerTag items. */
export type RetailersRetailerTagPaginator = {
  __typename?: 'retailersRetailerTagPaginator';
  /** A list of RetailerTag items. */
  data: Array<RetailersRetailerTag>;
  /** Pagination information about the list of items. */
  paginatorInfo: RetailersPaginatorInfo;
};

export type RetailersRetailerYear = {
  __typename?: 'retailersRetailerYear';
  active: Scalars['Boolean'];
  classes?: Maybe<Array<Maybe<RetailersRetailerClass>>>;
  created_at: Scalars['retailersDateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['retailersDateTime'];
};

export type RetailersRetailerYearFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['retailersDate']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['retailersDate']>;
};

/** A paginated list of RetailerYear items. */
export type RetailersRetailerYearPaginator = {
  __typename?: 'retailersRetailerYearPaginator';
  /** A list of RetailerYear items. */
  data: Array<RetailersRetailerYear>;
  /** Pagination information about the list of items. */
  paginatorInfo: RetailersPaginatorInfo;
};

/** The available SQL operators that are used to filter query results. */
export enum RetailersSqlOperator {
  /** Whether a value is within a range of values (`BETWEEN`) */
  Between = 'BETWEEN',
  /** Equal operator (`=`) */
  Eq = 'EQ',
  /** Greater than operator (`>`) */
  Gt = 'GT',
  /** Greater than or equal operator (`>=`) */
  Gte = 'GTE',
  /** Whether a value is within a set of values (`IN`) */
  In = 'IN',
  /** Whether a value is not null (`IS NOT NULL`) */
  IsNotNull = 'IS_NOT_NULL',
  /** Whether a value is null (`IS NULL`) */
  IsNull = 'IS_NULL',
  /** Simple pattern matching (`LIKE`) */
  Like = 'LIKE',
  /** Less than operator (`<`) */
  Lt = 'LT',
  /** Less than or equal operator (`<=`) */
  Lte = 'LTE',
  /** Not equal operator (`!=`) */
  Neq = 'NEQ',
  /** Whether a value is not within a range of values (`NOT BETWEEN`) */
  NotBetween = 'NOT_BETWEEN',
  /** Whether a value is not within a set of values (`NOT IN`) */
  NotIn = 'NOT_IN',
  /** Negation of simple pattern matching (`NOT LIKE`) */
  NotLike = 'NOT_LIKE'
}

/** Information about pagination using a simple paginator. */
export type RetailersSimplePaginatorInfo = {
  __typename?: 'retailersSimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum RetailersSortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum RetailersTrashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

/** Dynamic WHERE conditions for queries. */
export type RetailersWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<RetailersWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<RetailersWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<RetailersWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<Scalars['String']>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['retailersMixed']>;
};

/** Dynamic HAS conditions for WHERE condition queries. */
export type RetailersWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<RetailersWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<RetailersSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};
