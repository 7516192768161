import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetailerSalesTeamsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RetailerSalesTeamsQuery = { __typename?: 'Query', retailersRetailerSalesTeams?: { __typename?: 'retailersRetailerSalesTeamPaginator', data: Array<{ __typename?: 'retailersRetailerSalesTeam', id: string, name: string, active: boolean }> } | null | undefined };


export const RetailerSalesTeamsDocument = gql`
    query RetailerSalesTeams {
  retailersRetailerSalesTeams {
    data {
      id
      name
      active
    }
  }
}
    `;

/**
 * __useRetailerSalesTeamsQuery__
 *
 * To run a query within a React component, call `useRetailerSalesTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailerSalesTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailerSalesTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRetailerSalesTeamsQuery(baseOptions?: Apollo.QueryHookOptions<RetailerSalesTeamsQuery, RetailerSalesTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetailerSalesTeamsQuery, RetailerSalesTeamsQueryVariables>(RetailerSalesTeamsDocument, options);
      }
export function useRetailerSalesTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailerSalesTeamsQuery, RetailerSalesTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetailerSalesTeamsQuery, RetailerSalesTeamsQueryVariables>(RetailerSalesTeamsDocument, options);
        }
export type RetailerSalesTeamsQueryHookResult = ReturnType<typeof useRetailerSalesTeamsQuery>;
export type RetailerSalesTeamsLazyQueryHookResult = ReturnType<typeof useRetailerSalesTeamsLazyQuery>;
export type RetailerSalesTeamsQueryResult = Apollo.QueryResult<RetailerSalesTeamsQuery, RetailerSalesTeamsQueryVariables>;