import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type MoveNodeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  positions: Types.Scalars['Int'];
  parentId: Types.Scalars['ID'];
}>;


export type MoveNodeMutation = { __typename?: 'Mutation', configuratorMoveNode?: { __typename?: 'configuratorNode', id: string } | null | undefined };


export const MoveNodeDocument = gql`
    mutation MoveNode($id: ID!, $positions: Int!, $parentId: ID!) {
  configuratorMoveNode(id: $id, positions: $positions, parentId: $parentId) {
    id
  }
}
    `;
export type MoveNodeMutationFn = Apollo.MutationFunction<MoveNodeMutation, MoveNodeMutationVariables>;

/**
 * __useMoveNodeMutation__
 *
 * To run a mutation, you first call `useMoveNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveNodeMutation, { data, loading, error }] = useMoveNodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      positions: // value for 'positions'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useMoveNodeMutation(baseOptions?: Apollo.MutationHookOptions<MoveNodeMutation, MoveNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveNodeMutation, MoveNodeMutationVariables>(MoveNodeDocument, options);
      }
export type MoveNodeMutationHookResult = ReturnType<typeof useMoveNodeMutation>;
export type MoveNodeMutationResult = Apollo.MutationResult<MoveNodeMutation>;
export type MoveNodeMutationOptions = Apollo.BaseMutationOptions<MoveNodeMutation, MoveNodeMutationVariables>;