import { Slide, Snackbar } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import BackupIcon from '@material-ui/icons/Backup'
import clsx from 'clsx'
import React, { useContext } from 'react'
import { ErrorContext } from 'context/error/ErrorContext'
import { ModelContext } from 'context/model/ModelContext'
import { useExportModelMutation } from 'apollo/configurator/mutations/ExportModel.generated'
import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'
import { useNotify } from 'react-admin'

interface IExportButton {
    id: number
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 25
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative'
        },
        editedButton: {
            width: 150,
            position: 'absolute',
            margin: 0,
            zIndex: -1,
            height: 50,
            borderRadius: '0 10px 10px 0 ',
            backgroundColor: theme.palette.primary.light,
            display: 'flex',
            fontSize: 18,
            alignItems: 'center',
            paddingLeft: 40,
            fontWeight: 'bold'
        },
        buttonSuccess: {
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark
            }
        },
        buttonFailure: {
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.dark
            }
        },
        buttonNotExported: {
            backgroundColor: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: theme.palette.warning.dark
            }
        },
        fabProgress: {
            color: theme.palette.warning.light,
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1
        },
        buttonProgress: {
            color: theme.palette.primary.main,
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12
        },
        slideWrapper: {
            position: 'relative',
            height: 50,
            overflow: 'hidden',
            marginLeft: -25,
            color: theme.palette.warning.contrastText
        }
    })
)

const ExportButton = ({ id }: IExportButton) => {
    const notify = useNotify()
    const { state, setState } = useContext(ModelContext)
    const { setErrorState } = useContext(ErrorContext)
    const [success, setSuccess] = React.useState(false)
    const [mutationError, setMutationError] = React.useState(false)
    const [exportModel, { loading, error }] = useExportModelMutation({
        onCompleted({ configuratorExportModel: exportModel }) {
            if (exportModel) {
                setSuccess(true)
                setState({
                    ...state,
                    hasEdited: false,
                    hasChanges: false,
                    showEditedMessage: false
                })
                setMutationError(false)
                notify('The model has been exported successfully', { type: 'success' })
            } else if (error) {
                setMutationError(true)
                notify('The model has not been exported successfully', { type: 'error' })
            }
        },
        onError(error) {
            setMutationError(true)
            setErrorState({ hasError: true, message: error.message })
        }
    })
    const classes = useStyles()
    const buttonClassname = clsx({
        [classes.buttonSuccess]: true || success,
        [classes.buttonFailure]: mutationError,
        [classes.buttonNotExported]: state.hasChanges
    })
    const [open, setOpen] = React.useState(false)

    const [flushActionsCache] = useFlushActionsCacheMutation()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleConfirm = () => {
        exportModel({ variables: { id: id as any } })
        flushActionsCache()
        setOpen(false)
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        onClick={handleClickOpen}
                    >
                        <BackupIcon />
                    </Fab>
                    {loading && <CircularProgress size={68} className={classes.fabProgress} />}
                </div>
                <div className={classes.slideWrapper}>
                    <Slide
                        appear={true}
                        direction="right"
                        in={state?.showEditedMessage}
                        mountOnEnter
                        unmountOnExit
                    >
                        <p className={classes.editedButton}>Edited!</p>
                    </Slide>
                </div>
            </div>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {'Are you sure you want to publish the model?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        The current model is going to be exported. This may have non-reversible
                        consequences. Do you want to continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="default">
                        No
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ExportButton
